import { t } from "@lingui/macro";
import Presentation from "../UI/Presentation/Presentation";
import PreviewCard from "./PreviewCard/PreviewCard";
import { useEffect, useRef, useState } from "react";
import { GET_BLOGS } from "../../GraphQl/queries";
import HomeIlustration from "../UI/HomeIlustration/HomeIlustration";
import "./Blog.css";
import SortBlogPosts from "./Sort/SortBlogs";
import SearchBar from "./SearchBar/SearchBar";
import Markdown from "markdown-to-jsx";
import { MdChevronLeft } from "react-icons/md";
import { useLingui } from "@lingui/react";
import { useQuery } from "@apollo/client";

export default function Blog() {
  const { _ } = useLingui();
  const [blogs, setBlogs] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedBlog, setSelectedBlog] = useState(null);

  const originalBlogs = useRef([]);

  const { data } = useQuery(GET_BLOGS, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (!data) return;
    setBlogs(data.publication.posts.edges);
    originalBlogs.current = data.publication.posts.edges;
  }, [data]);
  useEffect(() => {
    if (searchText === "") {
      setBlogs([...originalBlogs.current]);
      return;
    }
    let filteredPosts = originalBlogs.current.filter((blog) => {
      return (
        blog.node.title.toLowerCase().includes(searchText.toLowerCase()) ||
        blog.node.content.text.toLowerCase().includes(searchText.toLowerCase())
      );
    });
    setBlogs([...filteredPosts]);
  }, [searchText]);

  // Sort posts alphabetically by title A-Z or Z-A
  function sortByTitle(ascending = true) {
    let sortedPosts = blogs;
    sortedPosts.sort((a, b) => {
      return a.node.title
        .toLowerCase()
        .localeCompare(b.node.title.toLowerCase());
    });
    if (!ascending) {
      sortedPosts.reverse();
    }
    setBlogs([...sortedPosts]);
  }
  function sortByDate() {
    let sortedPosts = blogs;
    sortedPosts.sort((a, b) => {
      return new Date(b.node.publishedAt) - new Date(a.node.publishedAt);
    });
    setBlogs([...sortedPosts]);
  }

  function handleBlogSelection(blog) {
    setSelectedBlog(blog);
  }

  return (
    <div className="blog-page-container">
      <div className="a11y-blog-presentation-container">
        <Presentation
          title={t`Blog: Insights, Trends, and Best Practices`}
          texts={[
            t`Your go-to hub for expert advice, industry updates, and actionable tips to make your digital content accessible for everyone`,
          ]}
          isLink
          buttonText={t`Get a free consultation`}
          className="blog-presentation"
        />
        <div className="home-page-ilustration-container">
          <HomeIlustration
            src={"/blog-presentation-image.jpg"}
            imgClassname={"blog-presentation-image"}
            showCircles={true}
          />
        </div>
      </div>
      <div className="preview-section">
        <div style={{ top: "0" }}>
          <div className="bottom-divider-container">
            <div
              className="blog-preview-top-divider"
              style={{
                backgroundImage: `url(${
                  process.env.PUBLIC_URL
                }${"/divider-g.svg"})`,
              }}
            ></div>
          </div>
        </div>

        {selectedBlog ? (
          <div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                style={{
                  width: "60%",
                  display: "flex",
                  justifyContent: "start",
                }}
              >
                <button
                  className="blog-button"
                  onClick={() => setSelectedBlog(null)}
                >
                  <MdChevronLeft size="20" />
                  Back to Blogs
                </button>
              </div>
            </div>
            <div className="read-blog-page">
              <div className="read-blog-content">
                <div className="article-img-preview">
                  <img
                    src={
                      selectedBlog.image
                        ? selectedBlog.image
                        : "./fallback_blog.svg"
                    }
                  />
                </div>
                <div className="title-container">
                  <h1>{selectedBlog.title}</h1>
                </div>
                <p>
                  By {selectedBlog.author} | {selectedBlog.formattedDate} |{" "}
                  {selectedBlog.readTime} min read
                </p>
                <Markdown>{selectedBlog.markdown}</Markdown>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="filters-section">
              <div className="filters-container">
                <SortBlogPosts
                  sortByTitle={sortByTitle}
                  sortByDate={sortByDate}
                />
                <SearchBar
                  searchText={searchText}
                  setSearchText={setSearchText}
                />
              </div>
            </div>
            <div className="blogs-preview-container">
              {blogs.map((blog) => (
                <PreviewCard
                  title={blog.node.title}
                  brief={blog.node.brief}
                  description={blog.node.brief}
                  image={blog.node.coverImage?.url}
                  date={blog.node.publishedAt}
                  author={blog.node.author.username}
                  readTime={blog.node.readTimeInMinutes}
                  html={blog.node.content.html}
                  text={blog.node.content.text}
                  markdown={blog.node.content.markdown}
                  onClick={handleBlogSelection}
                />
              ))}
            </div>
          </div>
        )}
        <div className="bottom-divider-container">
          <div
            className="blog-preview-bottom-divider"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL
              }${"/divider-w.svg"})`,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
