import { useEffect } from "react";
import "./PreviewCard.css";

export default function PreviewCard({
  title,
  brief,
  image,
  date,
  readTime,
  author,
  html,
  text,
  markdown,
  onClick,
}) {
  const parsedDate = new Date(date);
  useEffect(() => {
    console.log(image);
  }, []);

  const formattedDate = parsedDate.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  });

  function onClickHandler(event) {
    event.preventDefault();
    onClick({
      text: text,
      html: html,
      markdown: markdown,
      image,
      formattedDate,
      title,
      author,
      readTime,
    });
  }

  return (
    <div
      className="article-container"
      tabIndex={0}
      onClick={onClickHandler}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onClickHandler(e);
        }
      }}
    >
      <div className="article-img">
        <img src={image ? image : "./fallback_blog.svg"} />
      </div>
      <div className="preview-info">
        <p>{formattedDate}</p>
        <p className="info-author-readtime"> | {readTime} min read</p>
      </div>
      <div className="title-container">
        <h3>{title}</h3>
      </div>
      <p className="brief-preview">{brief}</p>
      <p className="author-readtime">
        By {author} | {readTime} min read
      </p>
    </div>
  );
}
