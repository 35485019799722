import { t } from "@lingui/macro";

export const scrollToContactForm = () => {
  const nameField = document.getElementById("name");

  window.location.hash = "contact-us";
  nameField.focus();
};

export const scrollToTop = () => {
  // Scroll to the top of the page
  window.scrollTo({ top: 0, behavior: "smooth" });
};

export const scrollToSection = () => {
  // Get the /#id part from href and scroll to it
  const href = window.location.href;
  try {
    const id = href.split("/#")[1];

    if (id) {
      const element = document.getElementById(id);

      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        return true;
      }
    }
    return false;
  } catch {
    return false;
  }
};

export const handleScrollAndFocus = (
  selector,
  options = { threshold: 1.0, rootMargin: "0px" }
) => {
  const element = document.querySelector(`${selector}`);

  if (!element) {
    console.warn(`Element not found with selector: ${selector}`);
    return;
  }

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        element.focus();
        observer.disconnect();
      }
    });
  }, options);

  element.scrollIntoView({ behavior: "smooth" });
  observer.observe(element);
};

export const navigateToHome = () => {
  window.location.href = "/?openWidget=true";
};

export const navigateFeedbackHub = () => {
  window.location.href = "/alliBot-feedback";
};

export const handleOpenWidget = (openWidget) => {
  window.addEventListener("load", () => {
    if (openWidget) {
      const targetElement = document.getElementById("our-services");

      window.scrollTo({
        top: targetElement.offsetTop,
        behavior: "smooth",
      });
      const widget = document.getElementById("wa11y-widget-icon");

      widget.focus();
      widget.click();
    }

    const newUrl = window.location.pathname;
    window.history.replaceState({}, "", newUrl);
  });
};

export const AlliWidgetFeaturesList = () => [
  {
    title: t`Premium Add-ons`,
    features: [
      t`Full Manual Audit Offered`,
      t`Up to 2 Expert Hours`,
      t`Website Remediation`,
    ],
  },
  {
    title: t`A11iWidget Features`,
    features: [
      t`Compliance with ADA, AODA, EAA, WCAG, Sections 508 & more`,
      t`24/7 Customer Support`,
      t`Page Structure analysis`,
      t`Universal Accessibility Support Profile`,
      t`Enhanced Readability & Text Customization`,
      t`Immersive Reading Support`,
      t`Virtual Keyboard Availability`,
      t`Scale Content`,
      t`Comprehensive Highlighting Suite`,
      t`Contrast & Saturation Color Adjustments`,
      t`Enhanced Audio Control`,
      t`Visual Content Management`,
      t`Animation Customization`,
    ],
  },
  {
    title: t`Allibot AI Features`,
    features: [
      t`AI-Powered Precision`,
      t`Immediate Code Solutions`,
      t`Up-to-Date Expertise`,
      t`Enterprise-Level Reliability`,
      t`24/7 Accessibility Support`,
      t`Advanced Accessibility Guideline Navigation`,
      t`Expert Consultations on WAI-ARIA Best Practices`,
      t`Comprehensive Accessibility Inquiries`,
      t`Code Analysis & Improvement Recommendations`,
      t`Cutting-edge AI-driven interface`,
      t`Intuitive Understanding of WCAG Accessibility Guidelines`,
      t`Streamlined Implementation Support`,
      t`Accelerated Accessibility Compliance`,
    ],
  },
];

export const openInNewTab = (url) => {
  window.open(url, "_blank", "noreferrer");
};

export const pricingPlanPackages = () => {
  return {
    title: t`Affordable Solutions for Every Business`,
    packages: [
      {
        name: t`A11iWidget Esential`,
        description: t`A11iWidget Product`,
        monthPrice: "$24.99",
        annualPrice: "$249.99",
        stripeMonthLink: "https://buy.stripe.com/9AQ4iggbV5TfbXG6oo",
        stripeAnnualLink: "https://buy.stripe.com/7sIdSQaRBbdzd1KbIL",
        custom: false,
        popular: false,
        features: AlliWidgetFeaturesList()[1].features,
        mobileFeatures: [
          {
            title: "Alliwidget Features",
            features: AlliWidgetFeaturesList()[1].features,
          },
        ],
      },
      {
        name: t`A11iBot Exclusive`,
        description: t`A11iBot Product`,
        monthPrice: "$37.99",
        annualPrice: "$379.99",
        stripeMonthLink: "https://buy.stripe.com/aEU9CA1h15Tf6Dm8wx",
        stripeAnnualLink: "https://buy.stripe.com/cN23ec4tdchD5zi4gk",
        custom: false,
        popular: false,
        features: AlliWidgetFeaturesList()[2].features,
        mobileFeatures: [
          {
            title: t`Allibot AI Features`,
            features: AlliWidgetFeaturesList()[2].features,
          },
        ],
      },
      {
        name: t`All-Inclusive Pro Bundle`,
        description: t`AlliWidget & Allibot Products`,
        monthPrice: "$49.99",
        annualPrice: "$490.99",
        stripeMonthLink: "https://buy.stripe.com/3csbKIaRB6Xj5zifZ0",
        stripeAnnualLink: "https://buy.stripe.com/cN2eWUbVFftP6Dm28d",
        custom: false,
        popular: true,
        features: [
          ...AlliWidgetFeaturesList()[1].features,
          ...AlliWidgetFeaturesList()[2].features,
        ],
        mobileFeatures: [
          {
            title: t`Alliwidget Features`,
            features: AlliWidgetFeaturesList()[1].features,
          },
          {
            title: t`Allibot AI Features`,
            features: AlliWidgetFeaturesList()[2].features,
          },
        ],
      },
      {
        name: t`Enterprise`,
        description: t`AlliWidget & Allibot Products`,
        monthPrice: t`Custom`,
        annualPrice: t`Custom`,
        stripeMonthLink: "",
        stripeAnnualLink: "",
        custom: true,
        popular: false,
        features: ["all"],
        mobileFeatures: [
          {
            title: t`Premium Add-ons`,
            features: AlliWidgetFeaturesList()[0].features,
          },
          {
            title: t`Alliwidget Features`,
            features: AlliWidgetFeaturesList()[1].features,
          },
          {
            title: t`Allibot AI Features`,
            features: AlliWidgetFeaturesList()[2].features,
          },
        ],
      },
    ],
  };
};

/**
 * General event to perform an action depending on pressed key
 * @param {event} e Triggered event
 * @param {list} keys List of expected keys
 * @param {list} callbacks List of actions to perform depending on key
 */
export function onKBDown(e, keys, callbacks) {
  for (let i = 0; i < keys.length; i++) {
    if (keys[i] === e.code) {
      e.preventDefault();
      return callbacks[i](e);
    }
  }
}
