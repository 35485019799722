import React from "react";
import "./ContactInfo.css";
import { Trans } from "@lingui/macro";

//import Logo from "../Logo/Logo"

function ContactInfo({ reference }) {
    return (
        <div style={{ marginTop: "4em" }} id="contact-us">
            <h2
                tabIndex="-1"
                ref={reference}
                style={{ textTransform: "uppercase", fontSize: "1em" }}
            >
                <Trans>Contact us</Trans>
            </h2>
            <div className="contact-info-item">
                <Trans>+1 (407) 535-1932</Trans>
            </div>
            <div className="contact-info-item">
                <Trans>a11ycontact@a11ysolutions.com</Trans>
            </div>
            <div className="contact-info-item">
                <Trans>Miami, Florida, US.</Trans>
            </div>
        </div>
    );
}
export default ContactInfo;
