import React from "react";
import ServiceCard from "../ServiceCard/ServiceCard";
import "./Services.css";
import { Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

function Services() {
  const { _ } = useLingui();
  return (
    <div className="a11y-services" role={"list"}>
      <ServiceCard
        header={_(msg`Functional testing`)}
        imgSrc="/functional-testing.png"
        style={{ marginTop: "53px" }}
        imgClass="services-functional-img"
        href="/functional"
        ariaLabel={_(msg`Learn more about functional testing`)}
      >
        <p>
          <Trans>
            We ensure your digital Products, Software,
            <br />
            Mobile and Web Applications deliver the performance
            <br />
            and experience they were designed for.
          </Trans>
        </p>
      </ServiceCard>
      <ServiceCard
        header={_(msg`Accessibility testing`)}
        imgSrc="/accessibility-testing.png"
        imgColor="#21ce99"
        style={{ marginTop: "53px" }}
        imgClass="services-accessibility-img"
        imgStyle={{ boxShadow: "0px 3px 40px #17D1984D" }}
        href="/accessibility"
        ariaLabel={_(msg`Learn more about accessibility testing`)}
      >
        <p>
          <Trans>
            We ensure your product is WCAG <br />
            compliance, allowing all people to have <br />
            equal access to it.
          </Trans>
        </p>
      </ServiceCard>
      <ServiceCard
        header={_(msg`Automation testing`)}
        imgSrc="/automation-testing.png"
        style={{ marginTop: "53px" }}
        imgClass="services-automation-img"
        href="/automation"
        ariaLabel={_(msg`Learn more about test automation`)}
      >
        <p>
          <Trans>
            We check your product is functioning <br />
            properly after changes are done, running <br />
            automated tests as often as needed.
          </Trans>
        </p>
      </ServiceCard>
      {/*<div className="services-circles-section">
                <div className="services-circle"></div>
            </div>*/}
    </div>
  );
}

export default Services;
