import { gql } from "@apollo/client";
export const HASHNODE_API_URL = "https://gql.hashnode.com/";

export const GET_BLOGS = gql`
  query Publication {
    publication(host: "a11ysolutions.hashnode.dev") {
      isTeam
      title
      posts(first: 30) {
        edges {
          node {
            author {
              username
            }
            publishedAt
            title
            brief
            url
            content {
              text
              markdown
            }
            coverImage {
              url
            }
            readTimeInMinutes
          }
        }
      }
    }
  }
`;
