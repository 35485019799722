import React from "react";
import Button from "../Button/Button";
import "./Presentation.css";
import { scrollToContactForm } from "../../Utils/Utils";
import LinkButton from "../LinkButton/LinkButton";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";

function Presentation({
  title,
  texts,
  buttonText,
  className,
  href,
  handleClick,
  isLink,
}) {
  const { i18n } = useLingui();

  return (
    <div className={"presentation-main " + className}>
      <h1 tabIndex={-1}>{title}</h1>

      {texts.map((text, index) => {
        return <text key={index}>{text}</text>;
      })}
      {isLink ? (
        <LinkButton
          id="presentation-button"
          href={href}
          size={i18n.locale === "es" ? "extra" : "large"}
          className="presentation-button"
          onClick={handleClick ? handleClick : scrollToContactForm}
        >
          {buttonText || t`Get a free consultation`}
        </LinkButton>
      ) : (
        <Button
          id="presentation-button"
          size={i18n.locale === "es" ? "extra" : "large"}
          className="presentation-button"
          onClick={handleClick ? handleClick : scrollToContactForm}
        >
          {buttonText || t`Get a free consultation`}
        </Button>
      )}
    </div>
  );
}

export default Presentation;
