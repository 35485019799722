import { IoMdSearch } from "react-icons/io";
import "./SearchBar.css";
export default function SearchBar({ searchText, setSearchText }) {
  return (
    <div className="search-bar">
      <IoMdSearch color="white" size="25" />

      <input
        type="text"
        placeholder="Search Blogs by title, keyword..."
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
    </div>
  );
}
