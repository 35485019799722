import React from "react";
import AchievementCard from "../UI/AchievementCard/AchievementCard";
import BicolorTitledText from "../UI/BicolorTitledText/BicolorTitledText";
// import Button from '../UI/Button/Button'
// import CardButton from "../UI/CardButton/CardButton"
import { IoOpenOutline } from "react-icons/io5";
import CheckedItem from "../UI/CheckedItem/CheckedItem";
import Illustration from "../UI/Illustration/Illustration";
import Presentation from "../UI/Presentation/Presentation";
import Toolset from "../UI/Toolset/Toolset";

import { Helmet } from "react-helmet";
import { scrollToContactForm } from "../Utils/Utils";

import "./Accessibility.css";
import LinkButton from "../UI/LinkButton/LinkButton";
import { Trans, msg, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import GetInTouchButton from "../UI/GetInTouchButton/GetInTouchButton";

function Accessibility() {
    const { _, i18n } = useLingui();
    return (
        <div id="automation-main" className="page-container">
            {/* <div className="presentation-circle-1"></div> */}

            <Helmet>
                <title>
                    {_(msg`Accessibility testing services | A11ysolutions`)}
                </title>
            </Helmet>

            <div className="a11y-page-top">
                <Presentation
                    title={t`Our expert team will guarantee your product's accessibility`}
                    texts={[
                        t`We combine technology and`,
                        t`expertise to help you meet your`,
                        t`compliance goals and make the web`,
                        t`accessible to everyone.`,
                    ]}
                    isLink
                    buttonText={t`Get a free consultation`}
                    className="accessibility-presentation-sign-custom-text"
                />
            </div>
            <Illustration
                pillText={t`Accessibility`}
                imgSrc={
                    window.innerWidth >= 768
                        ? "/explain-hand-with-laptop@2x.png"
                        : "/explain-hand-with-laptop.png"
                }
                containerStyle={{ position: "relative", marginTop: "50px" }}
                pillPositionClass="pill-position"
            />

            <section className="accesibility-a11y-qualities">
                <div className="accesibility-a11y-why-choose-us-qualities">
                    <BicolorTitledText
                        color1="black"
                        color2="#0D9E71"
                        color1Text={t`We offer the following`}
                        color2Text={t`accessibility audits for you:`}
                        className="bicolor-titled-container 11y-first-bicolored"
                    >
                        <Trans>
                            Our test automation engineers will put your testing
                            on the automation footing to find more defects in
                            less time. We do this in order to:
                        </Trans>
                    </BicolorTitledText>

                    <div className="a11y-qualities-top-section" role="list">
                        <AchievementCard imgSrc="/video-play.svg">
                            <h3>
                                <Trans>
                                    Video captioning and audio descriptions
                                </Trans>
                            </h3>
                            <p>
                                <Trans>
                                    By adding closed captions to your videos,
                                    and, describing visual information needed to
                                    understand the content, we'll allow more
                                    viewers to enjoy your media.
                                </Trans>
                            </p>
                        </AchievementCard>

                        <AchievementCard imgSrc="/utensils.svg">
                            <h3>
                                <Trans>Design</Trans>
                            </h3>
                            <p>
                                <Trans>
                                    We'll review if your design meets
                                    accessibility from the very beginning,
                                    reviewing your mockups/wireframes against
                                    WCAG standard, helping you to ensure that
                                    everyone has equal access.
                                </Trans>
                            </p>
                        </AchievementCard>
                    </div>
                </div>

                <div className="a11y-qualities-section" role="list">
                    <AchievementCard imgSrc="/desktop-pc.svg">
                        <h3>
                            <Trans>Websites</Trans>
                        </h3>
                        <p>
                            <Trans>
                                We will help you to make your website usable by
                                as many people as possible, whatever their
                                hardware, software, language, location, or
                                ability.
                            </Trans>
                        </p>
                    </AchievementCard>

                    <AchievementCard imgSrc="/group-icons.svg">
                        <h3>
                            <Trans>Mobile Applications</Trans>
                        </h3>
                        <p>
                            <Trans>
                                We will help you build natively and think about
                                accessibility early and often, making your
                                applications to be more accessible to people
                                with disabilities when they are using mobile
                                phones and other devices.
                            </Trans>
                        </p>
                    </AchievementCard>
                </div>

                <div className="a11y-qualities-section" role="list">
                    <AchievementCard imgSrc="/folder-data.svg">
                        <h3>
                            <Trans>
                                Pdfs, word documents, presentations or
                                spreadsheets
                            </Trans>
                        </h3>
                        <p>
                            <Trans>
                                All type of users will benefits from this
                                because your documents will be created to be as
                                easily readable by a sighted reader as a low
                                vision or non-sighted reader.
                            </Trans>
                        </p>
                    </AchievementCard>
                </div>
            </section>

            <section className="a11y-page second-info">
                <BicolorTitledText
                    color1="black"
                    color2="#0D9E71"
                    color1Text={t`Does your product need a VPAT`}
                    color2Text={t`(Voluntary Product Accessibility Template)?`}
                >
                    <Trans>
                        Creating your VPAT Document will help the world and
                        potential customers know how accessible your products
                        and services are to everyone.
                    </Trans>
                </BicolorTitledText>

                <div className="a11y-buttons">
                    {/* <Button className="card-button" size="large">
            <a aria-hidden="true" tabIndex={-1} className="removed-decorator-link" href="./VPAT_Template.pdf">
              View a Sample VPAT
            </a>
          </Button> */}
                    <LinkButton
                        href="./VPAT_Template.pdf"
                        className="automation"
                        size={i18n.locale == "es" ? "extra" : "large"}
                        ariaLabel="View a Sample VPAT (opens new window)"
                    >
                        <Trans>View a Sample VPAT</Trans>
                        <IoOpenOutline />
                    </LinkButton>

                    <GetInTouchButton style={{ marginTop: "0" }} />
                </div>
            </section>

            <section className="a11y-info-with-image inverted">
                <div className="vpat a11y-page second-info">
                    <div className="vpat vpat-explanation-header">
                        <h3>
                            <Trans>
                                WHAT IS A <b>VPAT</b>?
                            </Trans>
                        </h3>
                    </div>

                    <div className="vpat vpat-explanation-content">
                        <Trans>
                            A VPAT is a clear documentation on how your digital
                            product conforms to WCAG criteria. It clearly states
                            which accessibility standards your application
                            meets, and it advise users about any “accessibility
                            blockers” they may face while using your product.
                        </Trans>
                    </div>

                    <GetInTouchButton />
                </div>

                <div className="intermediate-image">
                    <div className="a11y-first-encircled-img">
                        <img
                            src="/hand-with-laptop-circular.png"
                            alt=""
                            className="a11y-first-image"
                        />
                    </div>
                    <div className="automation-first-blurred-circle"></div>
                </div>
            </section>

            <section className="a11y-info-with-image">
                <div className="vpat a11y-page second-info">
                    <div className="vpat vpat-explanation-header">
                        <h3>
                            <Trans>
                                WHY MY PRODUCT OR ORGANIZATION{" "}
                                <b>NEEDS A VPAT</b>?
                            </Trans>
                        </h3>
                    </div>

                    <div className="vpat vpat-explanation-content">
                        <Trans>
                            Because doing so your organization is telling the
                            world how accessible your product, service,
                            application or website is, which inevitably leads to
                            a better version of itself because websites that are
                            easier to navigate via screen reader are also easier
                            for search engines to understand, increasing web
                            traffic and better SEO.
                        </Trans>
                    </div>

                    <GetInTouchButton />
                </div>

                <div className="intermediate-image a11y-img-combo">
                    <img
                        src="/laptop-in-desktop-with-plants.png"
                        alt=""
                        className="automation-second-image"
                    />
                    <div className="automation-second-image-container">
                        <img
                            src="/procreator-ux-design-studio.png"
                            alt=""
                            className="automation-third-image"
                        />
                        <img
                            src="/explain-hand-with-laptop-circular.png"
                            alt=""
                            className="automation-fourth-image"
                        />
                    </div>
                    <div className="automation-second-circle-container">
                        <div className="automation-second-circle"></div>
                        <div className="automation-second-blurred-circle"></div>
                    </div>
                </div>
            </section>

            <section className="a11y-info-with-image inverted">
                <div className="checked-info">
                    <BicolorTitledText
                        color1="black"
                        color2="#0D9E71"
                        color1Text={t`We provide a detailed defects`}
                        color2Text={t`analysis report`}
                    >
                        <Trans>
                            An accessibility conformance evaluation requires a
                            combination of automated tools and manual
                            evaluation. Our experienced team of developers and
                            Accessibility testers will conduct a meticulous-full
                            assessment of your content and help your team to
                            walk through a detailed defects analysis report to
                            determine what you should do next.
                        </Trans>
                    </BicolorTitledText>

                    <div className="vpat-explanation-header no-margin">
                        <h3>
                            <Trans>THIS REPORT WILL INCLUDE:</Trans>
                        </h3>
                    </div>

                    <div className="checked-items" role="list">
                        <div className="checked-group-1">
                            <CheckedItem>
                                <Trans>Feature/Module/URL evaluated</Trans>
                            </CheckedItem>
                            <CheckedItem>
                                <Trans>Screenshots/Video</Trans>
                            </CheckedItem>
                            <CheckedItem>
                                <Trans>Compliance item violated</Trans>
                            </CheckedItem>
                            <CheckedItem>
                                <Trans>Issue description</Trans>
                            </CheckedItem>
                            <CheckedItem>
                                <Trans>Suggested solution</Trans>
                            </CheckedItem>
                        </div>

                        <div className="checked-group-1">
                            <CheckedItem>
                                <Trans>WCAG 2.0/2.1 | Section 508</Trans>
                            </CheckedItem>
                            <CheckedItem>
                                <Trans>
                                    Success Criteria Conformance Level (Level
                                    A|AA|AAA)
                                </Trans>
                            </CheckedItem>
                            <CheckedItem>
                                <Trans>Browsers & Screen Readers used</Trans>
                            </CheckedItem>
                            <CheckedItem>
                                <Trans>Among others</Trans>
                            </CheckedItem>
                        </div>
                    </div>

                    <GetInTouchButton />
                </div>

                <div className="intermediate-image a11y-img-combo">
                    <div className="a11y-first-encircled-img">
                        <img
                            src="/writing-in-paper.png"
                            alt=""
                            className="a11y-first-image"
                        />
                    </div>
                    <div className="a11y-first-blurred-circle"></div>
                </div>
            </section>

            <section className="a11y-info-with-image a11y-shadowed-section">
                <div className="checked-info">
                    <div>
                        <BicolorTitledText
                            color1="black"
                            color2="#0D9E71"
                            color1Text={t`Our expert team`}
                            color2Text={t`will help you also with`}
                        ></BicolorTitledText>

                        <div className="vpat-explanation-header no-margin">
                            <h3>
                                <Trans>VERIFICATION TESTING</Trans>
                            </h3>
                        </div>

                        <div className="vpat-explanation-content no-margin">
                            <Trans>
                                Once your team has fixed all the issues found in
                                our initial audit accessibility assessment we
                                will do the Verification Testing phase to
                                confirm that your product finally met all the
                                required accessibility guidelines.
                                <br />
                                <br />
                                Also, we will check that any change or addition
                                that you did hasn’t introduce any other
                                accessibility issues. This way we ensure your
                                product is understood, navigated, used and
                                accessible by everyone, regardless of the
                                technology or platform used to access it.
                            </Trans>
                        </div>
                        <div className="a11y-link_container">
                            <GetInTouchButton  />
                        </div>
                    </div>

                    <div className="last-image">
                        <div className="automation-third-circle-container">
                            <div className="automation-third-blurred-circle"></div>
                            <div className="a11y-fourth-circle"></div>
                        </div>
                        <img
                            src="/approved-agreement-allowed-validation-concept.png"
                            alt=""
                            className="automation-fith-image"
                        />
                    </div>
                </div>

                <div className="checked-info second">
                    <div className="second-text-info">
                        <div className="vpat-explanation-header no-margin">
                            <h3>
                                <Trans>ACCESSIBILITY MONITOR</Trans>
                            </h3>
                        </div>

                        <div className="vpat-explanation-content no-margin">
                            <Trans>
                                How do you ensure, every time you make any
                                change in your code or add a new feature, your
                                product remains accessible?
                                <br />
                                <br />
                                With Accessibility Monitoring we will test and
                                verify periodically that your website or web
                                application still met the Accessibility
                                Standards and Guidelines required to keep your
                                product accessible to every one.
                            </Trans>
                        </div>
                        <div className="a11y-link_container">
                            <GetInTouchButton />
                        </div>
                    </div>

                    <div className="last-image">
                        <div className="automation-third-circle-container">
                            <div className="automation-third-blurred-circle"></div>
                            <div className="a11y-fourth-circle"></div>
                        </div>
                        <img
                            src="/result-economy-success-report-solution-statistics.png"
                            alt=""
                            className="automation-fith-image"
                        />
                    </div>
                </div>

                <div className="checked-info">
                    <div>
                        <div className="vpat-explanation-header no-margin">
                            <h3>
                                <Trans>UX/UI AUDITS</Trans>
                            </h3>
                        </div>

                        <div className="vpat-explanation-content no-margin">
                            <Trans>
                                <b>We help your UX/UI Team</b> when building new
                                Products, Features, and Applications from the
                                very beginning to ensure your design
                                wireframes/mockups are in compliance with
                                accessibility.
                                <br />
                                <br />
                                Our experts will provide detailed annotations to
                                point out any potential accessibility issues and
                                will help you define the best components so your
                                Product will be WCAG compliant since Day 0.
                            </Trans>
                        </div>
                        <div className="a11y-link_container">
                            <GetInTouchButton />
                        </div>
                    </div>
                    <div className="last-image">
                        <div className="automation-third-circle-container">
                            <div className="automation-third-blurred-circle"></div>
                            <div className="a11y-fourth-circle"></div>
                        </div>
                        <img
                            src="/cooperation-analyst-chart-professional-paper-economics.png"
                            alt=""
                            className="automation-fith-image"
                        />
                    </div>
                </div>
            </section>

            <Toolset />
        </div>
    );
}

export default Accessibility;
