import { useState, useEffect } from "react";
import { t } from "@lingui/macro";
import { onKBDown } from "../../Utils/Utils";
import { LANGS, LANG_LIST } from "../../../constants/constants";
import ARROW_DOWN_ICON from "../../../assets/arrow_down.svg";
import { defaultLocale, dynamicActivate } from "../../../i18n";
import "./LangButton.css";
import { useLocation } from "react-router";

function LangButton() {
    const [isLangMenuDisplayed, setIsLangMenuDisplayed] = useState(false);
    const [lang, setLang] = useState(localStorage.getItem("lang"));
    const location = useLocation();

    const langTrigger = document.getElementById("nav-language");

    useEffect(() => {
        dynamicActivate(lang);
        localStorage.setItem("lang", lang);
        const html = document.querySelector("html");
        html.setAttribute("lang", lang);
        console.log("LangButton -> lang", lang);
    }, [lang]);

    useEffect(() => {
        setLang(localStorage.getItem("lang"));
        setIsLangMenuDisplayed(false);
    }, [location]);

    const onTabDown = (e, l) => {
        e.preventDefault();
        e.stopPropagation();

        let nextElement = e.shiftKey
            ? document.getElementById("contact-us-navbar-button")
            : location.pathname == "/sitemap" ?  document.getElementById("first_link") : document.getElementById("presentation-button") ;

        if (!isLangMenuDisplayed) {
            nextElement?.focus();
            return;
        }

        l && setLang(l);
        setIsLangMenuDisplayed(false);
        setTimeout(() => {
            nextElement?.focus();
        }, 200);
    };

    const onEscapeDown = (e) => {
        if (!isLangMenuDisplayed) return;
        e.preventDefault();
        e.stopPropagation();
        setIsLangMenuDisplayed(false);
        document.getElementById("nav-language")?.focus();
    };

    const onSelectOption = (l, e) => {
        e?.preventDefault();
        e?.stopPropagation();
        setIsLangMenuDisplayed(false);
        document.getElementById("nav-language")?.focus();
        setLang(l);
    };

    const focusNextOptionFunc = (currentIndex, inc, defaultElem) => {
        const nextId = `lang-opt-${currentIndex + inc}`;

        const elem = document.getElementById(nextId);
        if (elem) elem.focus();
        else defaultElem?.focus();
    };

    return (
        <>
            <div
                id="nav-language"
                className="header-btn"
                onClick={() => setIsLangMenuDisplayed(!isLangMenuDisplayed)}
                role="combobox"
                aria-label={t`Language Selector`}
                aria-controls="lang-menu"
                aria-haspopup="listbox"
                aria-expanded={`${isLangMenuDisplayed}`}
                tabIndex="0"
                onKeyDown={(e) =>
                    onKBDown(
                        e,
                        [
                            "Enter",
                            "Space",
                            "Tab",
                            "ArrowDown",
                            "ArrowUp",
                            "Escape",
                        ],
                        [
                            () => setIsLangMenuDisplayed(!isLangMenuDisplayed),
                            () => setIsLangMenuDisplayed(!isLangMenuDisplayed),
                            () => onTabDown(e),
                            () =>
                                isLangMenuDisplayed
                                    ? focusNextOptionFunc(-1, 1)
                                    : setIsLangMenuDisplayed(true),
                            () =>
                                isLangMenuDisplayed &&
                                setIsLangMenuDisplayed(false),
                            () => onEscapeDown(e),
                        ]
                    )
                }
            >
                <img
                    src={LANGS[lang]["icon"]}
                    width="22"
                    height="22"
                    alt=""
                    className="header-icon"
                    style={{ border: "2px solid white", borderRadius: "6px" }}
                />
                <div
                    id="language-text"
                    className="header-text"
                    style={{ color: "black" }}
                >
                    {LANGS[lang]["text"]}
                </div>
                <img
                    src={ARROW_DOWN_ICON}
                    width="22"
                    height="22"
                    alt=""
                    className="header-icon"
                    style={{ order: 2 }}
                />
            </div>
            <ul
                id="lang-menu"
                aria-hidden={!isLangMenuDisplayed}
                style={{ display: isLangMenuDisplayed ? "block" : "none" }}
                role="listbox"
            >
                {LANG_LIST.map((l, i) => (
                    <li
                        key={i}
                        lang={l}
                        id={`lang-opt-${i}`}
                        className="lang-opt"
                        role="option"
                        onClick={(e) => onSelectOption(l, e)}
                        tabIndex="0"
                        onKeyDown={(e) =>
                            onKBDown(
                                e,
                                [
                                    "Enter",
                                    "Space",
                                    "Tab",
                                    "ArrowDown",
                                    "ArrowUp",
                                    "Escape",
                                ],
                                [
                                    () => onSelectOption(l),
                                    () => onSelectOption(l),
                                    () => onTabDown(e, l),
                                    () => focusNextOptionFunc(i, 1),
                                    () =>
                                        focusNextOptionFunc(i, -1, langTrigger),
                                    () => onEscapeDown(e),
                                ]
                            )
                        }
                    >
                        <img
                            src={LANGS[l]["icon"]}
                            alt=""
                            width={24}
                            height={24}
                        />
                        <span
                            className="lang-opt-text"
                            style={{ color: "black" }}
                        >
                            {LANGS[l]["text"]}
                        </span>
                    </li>
                ))}
            </ul>
        </>
    );
}

export default LangButton;
