import { Trans } from "@lingui/macro";
import { scrollToContactForm } from "../../Utils/Utils";
import LinkButton from "../LinkButton/LinkButton";
import { useLingui } from "@lingui/react";

export default function GetInTouchButton({ variant, style }) {
    const { i18n } = useLingui();
    const currentLocale = i18n.locale;
    return (
        <LinkButton
            variant={variant}
            onClick={scrollToContactForm}
            className="presentation-button"
            style={style}
            size={i18n.locale == "es" ? "large" : "medium"}
        >
            <Trans>Get in touch</Trans>
        </LinkButton>
    );
}
