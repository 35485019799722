import { useLocation } from "react-router";
import "./ReadBlogPage.css";
import React from "react";
import Markdown from "markdown-to-jsx";

export default function ReadBlogPage() {
  const location = useLocation();
  const { markdown, image, formattedDate, title, author, readTime } =
    location.state || {};

  return (
    <div className="read-blog-page">
      <div className="read-blog-content">
        <div className="article-img-preview">
          <img src={image} />
        </div>
        <div className="title-container">
          <h1>{title}</h1>
        </div>
        <p>
          By {author} | {formattedDate} | {readTime} min read
        </p>
        <Markdown>{markdown}</Markdown>
      </div>
    </div>
  );
}
