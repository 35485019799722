import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./Components/Home/Home";
import Accessibility from "./Components/Accessibility/Accessibility";
import Functional from "./Components/Functional/Functional";
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy";
import Automation from "./Components/Automation/Automation";
import StandardRegulations from "./Components/StandardsRegulations/StandardRegulations";
import Sitemap from "./Components/Sitemap/Sitemap";
import { useEffect, useState } from "react";
import AlliBot from "./Components/AlliBot/AlliBot";
import { AlliWidget } from "./Components/AlliWidget/AlliWidget";
import { AlliBotFeedback } from "./Components/AlliBotFeedback/AlliBotFeedback";
import { scrollToSection, scrollToTop } from "./Components/Utils/Utils";
import Blog from "./Components/Blog/Blog";
import ReadBlogPage from "./Components/Blog/ReadBlogPage/ReadBlogPage";
import AccessibilityStatement from "./Components/AccessibilityStatement/AccessibilityStatement";

function App() {
  const [currentUrl] = useState(window.location.pathname);
  const location = useLocation();

  useEffect(() => {
    const scrolled = scrollToSection();
    if (!scrolled) scrollToTop();
  }, [location]);

  useEffect(() => {
    if (
      currentUrl === "/" ||
      currentUrl === "/standards" ||
      currentUrl === "/privacy-policy"
    )
      return;
    const layout = document.querySelector(".layout-content");
    if (
      currentUrl === "/alliWidget" ||
      currentUrl === "/alliBot" ||
      currentUrl === "/sitemap"
    ) {
      layout.style.background = "linear-gradient(#21ce99 1500px, white 20px)";
    } else {
      layout.style.background = "linear-gradient(#21ce99 950px, white 20px)";
    }
  }, [currentUrl]);

  return (
    <main className="App">
      <Routes>
        {/**/} <Route exact path="/" element={<Home />} />
        <Route path="/accessibility" element={<Accessibility />} />
        {/**/} <Route path="/functional" element={<Functional />} />
        {/**/} <Route path="/alliBot" element={<AlliBot />} />
        {/**/} <Route path="/alliWidget" element={<AlliWidget />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        {/**/}
        <Route path="/a11y-statement" element={<AccessibilityStatement />} />
        {/**/} <Route path="/automation" element={<Automation />} />
        {/**/}
        <Route path="/alliBot-feedback" element={<AlliBotFeedback />} />
        <Route path="/standards" element={<StandardRegulations />} />
        {/**/} <Route path="/sitemap" element={<Sitemap />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/read-blog" element={<ReadBlogPage />} />
      </Routes>
    </main>
  );
}

export default App;
