import React from 'react'
import './Button.css'

const Button = React.forwardRef(
  (
    {
      children,
      size = 'medium',
      variant = 'dark',
      id,
      className = '',
      style,
      onClick,
      ariaLabel,
      ariaLabelledby,
      role,
    },
    ref
  ) => {
    return (
      <>
        <button
          ref={ref}
          id={id}
          className={`a11y-button a11y-button-${size} a11y-button-${variant} ${className}`}
          style={{ ...style }}
          onClick={onClick}
          aria-label={ariaLabel}
          aria-labelledby={ariaLabelledby}
          role={role}
        >
          {children}
        </button>
      </>
    )
  }
)

export default Button
