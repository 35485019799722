import { useLingui } from "@lingui/react";
import { msg } from "@lingui/macro";

const VideoPresentation = ({ source }) => {
    const { _ } = useLingui();
    return (
        <iframe
            className="alliWidget-video"
            width="560"
            height="400"
            src={source}
            title={_(
                msg`A11iWidget - Your ultimate automated Web Accessibility Solution`
            )}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        ></iframe>
    );
};
export default VideoPresentation;
