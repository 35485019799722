import { IoStar, IoStarOutline } from 'react-icons/io5'
import './Star.css'

const Star = ({ filled }) =>
  filled ? (
    <IoStar color='orange' className="black-border-star" size="22" />
  ) : (
    <IoStarOutline className="black-border-star" size="22" />
  )

export default Star
