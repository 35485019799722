import React, { useState, useEffect, useRef } from "react";
import FirmContent from "./FirmContent";
import SAMPLES from "./Samples";
import "./FirmSamples.css";

function FirmSamples() {
    const keys = Object.keys(SAMPLES());
    const [selectedFirm, setSelectedFirm] = useState(keys[0]);
    const [activeTab, setActiveTab] = useState(0);
    const tabRefs = useRef([]);

    useEffect(() => {
        tabRefs.current = tabRefs.current.slice(0, keys.length);
    }, [keys.length]);

    const handleKeyDown = (event, index) => {
        let newIndex = index;

        switch (event.key) {
            case "ArrowUp":
                event.preventDefault();
                newIndex = index === 0 ? keys.length - 1 : index - 1;
                setActiveTab(newIndex);
                setSelectedFirm(keys[newIndex]);
                break;
            case "ArrowDown":
                event.preventDefault();
                newIndex = index === keys.length - 1 ? 0 : index + 1;
                setActiveTab(newIndex);
                setSelectedFirm(keys[newIndex]);
                break;
            case " ":
                event.preventDefault();
                break;
        }

        const ref = tabRefs.current[newIndex];
        if (ref) {
            ref.focus();
        }
    };

    return (
        <div className="firm-samples">
            <div className="firm-listing">
                <ul
                    role="tablist"
                    aria-labelledby="tablist-1"
                    className="firm-list"
                >
                    {keys.map((firm, index) => (
                        <React.Fragment key={firm}>
                            <li
                                id={`tab-${index}`}
                                role="tab"
                                tabIndex={activeTab === index ? 0 : -1}
                                aria-controls={`tabpanel-${index}`}
                                aria-selected={activeTab === index}
                                className={`firm selected-${
                                    firm === selectedFirm
                                }`}
                                onClick={() => {
                                    setSelectedFirm(firm);
                                    setActiveTab(index);
                                }}
                                onKeyDown={(event) =>
                                    handleKeyDown(event, index)
                                }
                                ref={(el) => (tabRefs.current[index] = el)}
                            >
                                <span className="firm-name">{firm}</span>
                            </li>
                            {firm === selectedFirm && (
                                <FirmContent
                                    firmName={firm}
                                    className="firm-content-mobile"
                                    value={activeTab}
                                />
                            )}
                        </React.Fragment>
                    ))}
                </ul>
            </div>

            <FirmContent
                firmName={selectedFirm}
                className="firm-content-desktop"
                value={activeTab}
            />
        </div>
    );
}

export default FirmSamples;
