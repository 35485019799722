import React from "react";
import "./PresentationSign.css";
import { scrollToContactForm } from "../../Utils/Utils";
import LinkButton from "../LinkButton/LinkButton";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";

function PresentationSign({ className }) {
    const { i18n } = useLingui();

    return (
        <div className={"presentation-sign " + className}>
            <h1
                tabIndex={-1}
                style={{ zIndex: 2, display: "flex", flexDirection: "column" }}
            >
                <Trans>
                    <b>It's time</b>
                    <b>to evolve!</b>
                </Trans>
            </h1>

            <p>
                <Trans>
                    We are committed to delivering <br />
                    your product with the best quality ever.
                </Trans>
            </p>

            <LinkButton
                id="presentation-button"
                style={{ marginTop: "24px" }}
                onClick={scrollToContactForm}
                className="presentation-button"
                size={i18n.locale == "es" ? "extra" : "large"}
            >
                <Trans>Get a free consultation</Trans>
            </LinkButton>
        </div>
    );
}

export default PresentationSign;
