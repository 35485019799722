import "./SkipLink.css";
import { Trans } from "@lingui/macro";

export const SkipLink = () => {
    const handleScrollAndFocus = (selector) => {
        let element;
        if (selector === "main") {
            element = document.querySelector("main h1");
        } else if (selector === "footer") {
            element = document.getElementById("skip-footer");
        }

        if (!element) {
            console.warn(`Element not found with selector: ${selector}`);
            return;
        }

        element.focus();
    };

    return (
        <div className="skip__link">
            <a onClick={() => handleScrollAndFocus("main")} href="#">
                <Trans>Skip to content</Trans>
            </a>
            <span className="separator">-</span>
            <a onClick={() => handleScrollAndFocus("footer")} href="#">
                <Trans>Skip to footer</Trans>
            </a>
        </div>
    );
};
