import "./SortBlogs.css";
export default function SortBlogPosts({ sortByTitle, sortByDate }) {
  //Handle the select change event
  const handleChange = (e) => {
    if (e.target.value === "most-recent") {
      sortByDate();
    } else if (e.target.value === "alpha-asc") {
      sortByTitle(true);
    } else if (e.target.value === "alpha-desc") {
      sortByTitle(false);
    }
  };

  return (
    <div className="sort-dropdown">
      <label htmlFor="sort-dropdown">Filter</label>
      <select id="sort-dropdown" onChange={handleChange}>
        <option value="most-recent">Most Recent</option>
        <option value="alpha-asc">Alpha (A-Z)</option>
        <option value="alpha-desc">Alpha (Z-A)</option>
      </select>
    </div>
  );
}
