import { useLingui } from "@lingui/react";
import "./ToolList.css";
import { msg } from "@lingui/macro";

function ToolList() {
    const { _ } = useLingui();
    return (
        <div className="external-container" role={"list"}>
            {/* <div className="row-container"> */}
            <div className="tool-image" role="listitem">
                <img
                    src="/aws-image.svg"
                    alt={_(msg`amazon web services logo`)}
                ></img>
            </div>
            <div className="tool-image" role="listitem">
                <img src="/jaws-image.svg" alt={_(msg`jaws logo`)}></img>
            </div>
            <div className="tool-image" role="listitem">
                <img src="/postman-image.svg" alt={_(msg`postman logo`)}></img>
            </div>
            {/* </div> */}

            {/* <div className="row-container"> */}
            <div className="tool-image" role="listitem">
                <img src="/se-image.svg" alt={_(msg`selenium logo`)}></img>
            </div>
            <div className="tool-image" role="listitem">
                <img
                    src="/awslambda-image.svg"
                    alt={_(msg`amazon web services lambda logo`)}
                ></img>
            </div>
            <div className="tool-image" role="listitem">
                <img
                    src="/puppeteer-image.svg"
                    alt={_(msg`puppeteer logo`)}
                ></img>
            </div>
            {/* </div> */}

            {/* <div className="row-container"> */}
            <div className="tool-image" role="listitem">
                <img
                    src="/confluence-image.svg"
                    alt={_(msg`confluence logo`)}
                ></img>
            </div>
            <div className="tool-image" role="listitem">
                <img
                    src="/browserstack-image.svg"
                    alt={_(msg`browser stack logo`)}
                ></img>
            </div>
            <div className="tool-image" role="listitem">
                <img
                    src="/invision-image.svg"
                    alt={_(msg`invision logo`)}
                ></img>
            </div>
            {/* </div> */}

            {/* <div className="row-container"> */}
            <div className="tool-image" role="listitem">
                <img src="/jenkins-image.svg" alt={_(msg`jenkins logo`)}></img>
            </div>
            <div className="tool-image" role="listitem">
                <img
                    src="/voiceover-image.svg"
                    alt={_(msg`voice over logo`)}
                ></img>
            </div>
            <div className="tool-image" role="listitem">
                <img src="/nvda-image.svg" alt={_(msg`nvda logo`)}></img>
            </div>
            {/* </div> */}

            {/* <div className="row-container"> */}
            {/* <div className="tool-image" role='listitem'><img src="/talkback-image.svg" alt="talk back logo" ></img></div> */}
            <div className="tool-image" role="listitem">
                <img
                    src="/cucumber-image.svg"
                    alt={_(msg`cucumber logo`)}
                ></img>
            </div>
            <div className="tool-image" role="listitem">
                <img src="/figma-image.svg" alt={_(msg`figma logo`)}></img>
            </div>
            {/* </div> */}

            {/* <div className="row-container"> */}
            <div className="tool-image" role="listitem">
                <img src="/jira-image.svg" alt={_(msg`jira logo`)}></img>
            </div>
            <div className="tool-image" role="listitem">
                <img src="/amp-image.svg" alt={_(msg`amp logo`)}></img>
            </div>
            <div className="tool-image" role="listitem">
                <img src="/ax-image.svg" alt={_(msg`ax logo`)}></img>
            </div>
            {/* </div> */}

            {/* <div className="row-container"> */}
            <div className="tool-image" role="listitem">
                <img src="/xd-image.svg" alt={_(msg`xd logo`)}></img>
            </div>
            <div className="tool-image" role="listitem">
                <img src="/soapui-image.svg" alt={_(msg`soap ui logo`)}></img>
            </div>
            <div className="tool-image" role="listitem">
                <img src="/testng-image.svg" alt={_(msg`test ng logo`)}></img>
            </div>
            {/* </div> */}

            {/* <div className="row-container"> */}
            <div className="tool-image" role="listitem">
                <img
                    src="/abstract-image.svg"
                    alt={_(msg`abstract logo`)}
                ></img>
            </div>
            <div className="tool-image" role="listitem">
                <img src="/maven-image.svg" alt={_(msg`maven logo`)}></img>
            </div>
            {/* </div> */}
        </div>
    );
}

export default ToolList;
