import React from "react";
import BicolorTitledText from "../UI/BicolorTitledText/BicolorTitledText";
import CheckedItem from "../UI/CheckedItem/CheckedItem";
import Illustration from "../UI/Illustration/Illustration";
import Presentation from "../UI/Presentation/Presentation";
import Toolset from "../UI/Toolset/Toolset";

import { Helmet } from "react-helmet";
import { FiTrendingDown } from "react-icons/fi";
import { AiFillCheckCircle, AiFillStar } from "react-icons/ai";
import { FaClipboardList } from "react-icons/fa";
import { GiAlarmClock } from "react-icons/gi";

import "./Automation.css";
import { Trans, t, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import GetInTouchButton from "../UI/GetInTouchButton/GetInTouchButton";
// import { scrollToContactForm } from "../Utils/Utils"

const TRENDING_ICON = (
    <FiTrendingDown
        className="checked-item-icon"
        role="img"
        aria-hidden="true"
    />
);
const CHECKED_ICON = (
    <AiFillCheckCircle
        className="checked-item-icon"
        role="img"
        aria-hidden="true"
    />
);
const STAR_ICON = (
    <AiFillStar className="checked-item-icon" role="img" aria-hidden="true" />
);
const CLIPBOARD_ICON = (
    <FaClipboardList
        className="checked-item-icon"
        role="img"
        aria-hidden="true"
    />
);
const ALARM_ICON = (
    <GiAlarmClock className="checked-item-icon" role="img" aria-hidden="true" />
);

function Automation() {
    const { _ } = useLingui();
    return (
        <div id="automation-main" className="page-container">
            {/* <div className="presentation-circle-1"></div> */}

            <Helmet>
                <title>
                    {_(msg`Automation testing services | A11ysolutions`)}
                </title>
            </Helmet>

            <div className="page-top">
                <Presentation
                    title={t`Accelerate Automation with us`}
                    texts={[
                        t`The tools and teams you need for`,
                        t`simple, scalable test automation and`,
                        t`faster speed to market`,
                    ]}
                    isLink
                    buttonText={t`Get a free consultation`}
                />
            </div>
            <Illustration
                pillText={t`Automation`}
                imgSrc={
                    window.innerWidth >= 768
                        ? "/working-code@2x.png"
                        : "/working-code.png"
                }
                containerStyle={{ position: "relative", marginTop: "50px" }}
                pillPositionClass="pill-position"
            />

            <section className="info-with-image automation-section first-info">
                <div className="checked-info">
                    <BicolorTitledText
                        color1="#0D9E71"
                        color2="black"
                        color1Text={t`Scalable test`}
                        color2Text={t`automation`}
                        className="checked-info-header"
                    >
                        <Trans>
                            Our test automation engineers will put your testing
                            on the automation footing to find more defects in
                            less time. We do this in order to:
                        </Trans>
                    </BicolorTitledText>

                    <div className="bicolor-titled-container" role="list">
                        <CheckedItem>
                            <Trans>Effectively develop test scripts</Trans>
                        </CheckedItem>
                        <CheckedItem>
                            <Trans>Thoroughly assess every test cycle</Trans>
                        </CheckedItem>
                        <CheckedItem>
                            <Trans>Produce better software and faster</Trans>
                        </CheckedItem>
                        <CheckedItem>
                            <Trans>
                                Build test automation environment rapidly
                            </Trans>
                        </CheckedItem>
                    </div>

                    <GetInTouchButton />
                </div>

                <div className="intermediate-image a11y-img-combo">
                    <div className="automation-first-encircled-img">
                        <img
                            src="/tablet-unpash.png"
                            alt=""
                            className="automation-first-image"
                        />
                    </div>
                    <div className="automation-first-blurred-circle"></div>
                </div>
            </section>

            <section className="info-with-image automation-section second-info">
                <div className="checked-info">
                    <BicolorTitledText
                        color1="#0D9E71"
                        color2="black"
                        color1Text={t`Faster speed`}
                        color2Text={t`to market`}
                        className="checked-info-header"
                    >
                        <Trans>
                            We prepare the right test environment to smoothly
                            run test cases for all targeted platforms, devices,
                            and browsers and ensure appropriate resource
                            monitoring in order to deliver your products faster.
                            Key benefits we offer to you are:
                        </Trans>
                    </BicolorTitledText>

                    <div className="bicolor-titled-container" role="list">
                        <CheckedItem>
                            <Trans>
                                Our test automation engineers create re-usable
                                high-level application-specific libraries to
                                reduce testing time and costs.
                            </Trans>
                        </CheckedItem>
                        <CheckedItem>
                            <Trans>
                                We run tests after every change in your software
                                which will help you to deliver new product
                                features even more quickly and efficiently while
                                not compromising on software quality.
                            </Trans>
                        </CheckedItem>
                        <CheckedItem>
                            <Trans>
                                We help you to get away from slow releases and
                                defects found in production.
                            </Trans>
                        </CheckedItem>
                        <CheckedItem>
                            <Trans>
                                With our help, you will become Proactive instead
                                of Reactive
                            </Trans>
                        </CheckedItem>
                    </div>

                    <GetInTouchButton />
                </div>

                <div className="intermediate-image a11y-img-combo">
                    <img
                        src="/women-with-mobile.png"
                        alt=""
                        className="automation-second-image"
                    />
                    <div className="automation-second-image-container">
                        <img
                            src="/laptop-and-tablet.png"
                            alt=""
                            className="automation-third-image"
                        />
                        <img
                            src="/laptop-and-screen.png"
                            alt=""
                            className="automation-fourth-image"
                        />
                    </div>
                    <div className="automation-second-circle-container">
                        <div className="automation-second-circle"></div>
                        <div className="automation-second-blurred-circle"></div>
                    </div>
                </div>
            </section>

            <section className="info-with-image automation-section">
                <div className="checked-info">
                    <BicolorTitledText
                        color1="#0D9E71"
                        color2="black"
                        color1Text={t`Ensure better`}
                        color2Text={t`quality`}
                        className="checked-info-header"
                    >
                        <Trans>
                            We will catch every bug! After your team deploys any
                            fix/update to your code, our regression tests come
                            into play. Our expertise will help you with
                        </Trans>
                    </BicolorTitledText>

                    <div className="bicolor-titled-container" role="list">
                        <CheckedItem>
                            <Trans>Schedule your Test Execution 24/7</Trans>
                        </CheckedItem>
                        <CheckedItem>
                            <Trans>Easy Regression Testing</Trans>
                        </CheckedItem>
                        <CheckedItem>
                            <Trans>Powerful Test Reports</Trans>
                        </CheckedItem>
                        <CheckedItem>
                            <Trans>
                                Ability to Perform Testing on Multiple Platforms
                                in Parallel
                            </Trans>
                        </CheckedItem>
                        <CheckedItem>
                            <Trans>Maximize the Test Coverage</Trans>
                        </CheckedItem>
                        <CheckedItem>
                            <Trans>Scalability</Trans>
                        </CheckedItem>
                    </div>

                    <GetInTouchButton />
                </div>

                <div className="intermediate-image a11y-img-combo">
                    <div className="automation-first-encircled-img">
                        <img
                            src="/laptop-with-code.png"
                            alt=""
                            className="automation-first-image"
                        />
                    </div>
                    <div className="automation-first-blurred-circle"></div>
                </div>
            </section>

            <section className="shadowed-section">
                <section className="info-with-image automation-section">
                    <div className="checked-info">
                        <BicolorTitledText
                            color1="#0D9E71"
                            color2="black"
                            color1Text={t`Key Benefits`}
                            color2Text={t`we offer`}
                        ></BicolorTitledText>

                        <div className="checked-items" role="list">
                            <div>
                                <CheckedItem icon={TRENDING_ICON}>
                                    <Trans>Reduce Regression Cost</Trans>
                                </CheckedItem>
                                <CheckedItem icon={CHECKED_ICON}>
                                    <Trans>Better Testing Quality</Trans>
                                </CheckedItem>
                                <CheckedItem icon={STAR_ICON}>
                                    <Trans>Better Resource utilization</Trans>
                                </CheckedItem>
                            </div>
                            <div className="second-section-items">
                                <CheckedItem icon={CLIPBOARD_ICON}>
                                    <Trans>Multi-platform testing</Trans>
                                </CheckedItem>
                                <CheckedItem icon={ALARM_ICON}>
                                    <Trans>Faster time to market</Trans>
                                </CheckedItem>
                            </div>
                        </div>
                    </div>

                    <div className="last-image">
                        <div className="automation-third-circle-container">
                            <div className="automation-third-circle"></div>
                            <div className="automation-third-blurred-circle"></div>
                            <div className="automation-fourth-circle"></div>
                        </div>
                        <img
                            src="/programmer.png"
                            alt=""
                            className="automation-fith-image"
                        />
                    </div>
                </section>
            </section>

            <Toolset />
        </div>
    );
}

export default Automation;
