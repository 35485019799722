import React from 'react'
// import Button from "../Button/Button";
import './TryItNow.css'
import LinkButton from '../LinkButton/LinkButton'

function TryItNow({ text, subText, buttonText, handleNavigate, children }) {
  return (
    <div className="tryItNow">
      <div className="tryItNow-content">
        <div className="tryItNow-text">
          <h2>{text}</h2>
          <p>{subText}</p>
        </div>

        <LinkButton onClick={handleNavigate} size="large">
          {buttonText}
        </LinkButton>
      </div>
      {children}
    </div>
  )
}

export default TryItNow
