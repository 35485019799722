import React from "react";
import "./AccessibilityStatement.css";
import { Helmet } from "react-helmet";
import Presentation from "../UI/Presentation/Presentation";
import HomeIlustration from "../UI/HomeIlustration/HomeIlustration";
import { Trans, msg, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { ImageDivider } from "../UI/ImageDivider/ImageDivider";

function AccessibilityStatement() {
  const { _ } = useLingui();

  return (
    <div id="privacy-policy-main" className="privacy-policy-page-container">
      <Helmet>
        <title>{_(msg`Accessibility Statement | A11ysolutions`)}</title>
      </Helmet>

      <div className="privacy-policy-presentation-container">
        <div className="page-top">
          <Presentation
            title={t`Accessibility Statement`}
            texts={[""]}
            isLink
            buttonText={t`Get a free consultation`}
            className={t`privacy-policy-presentation-sign`}
          />
        </div>
        <div className="privacy-policy-ilustration-container">
          <HomeIlustration
            firstPillText={t`Secure`}
            secondPillText={t`Reliable`}
            thirdPillText={t`Ethical`}
          />
        </div>
      </div>
      <div className="privacy-policy-statement">
        <div style={{ position: "relative", width: "100%", height: "auto" }}>
          <ImageDivider top={true} variantTopGreen={true} />
        </div>
        <div className="privacy-policy-content">
          <h2>
            <Trans>Accessibility Statement for a11ySolutions</Trans>
          </h2>

          <p>
            <Trans>
              We want everyone who visits the a11ySolutions website to feel
              welcome and find the experience rewarding. We guarantee compliance
              with{" "}
              <a
                href="https://www.section508.gov/manage/laws-and-policies/"
                target="_blank"
              >
                Section 508
              </a>{" "}
              of the Rehabilitation Act 1998, the{" "}
              <a
                href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=COM%3A2015%3A0615%3AFIN"
                target="_blank"
              >
                European Accessibility Act (EAA)
              </a>
              , and Canadian accessibility laws to ensure our website is
              accessible to a global audience.
            </Trans>
          </p>

          <h3>
            <Trans>What are we doing?</Trans>
          </h3>
          <p>
            <Trans>
              To help make the a11ySolutions website a positive place for
              everyone, we refer to the{" "}
              <a href="https://www.w3.org/TR/WCAG22/" target="_blank">
                Web Content Accessibility Guidelines (WCAG) 2.2.
              </a>{" "}
              These guidelines explain how to make web content more accessible
              for people with disabilities, more compatible with assistive
              technology, and more user-friendly for everyone. The guidelines
              have three levels of accessibility (A, AA, and AAA). We’ve chosen
              Level A & AA as the target for the a11ySolutions website.
            </Trans>
          </p>
          <h3>
            <Trans>How are we doing?</Trans>
          </h3>
          <p>
            <Trans>
              We are dedicated to making the a11ySolutions website accessible to
              all users and have engaged our own certified professionals to
              achieve this goal. Our accessibility experts conduct manual
              testing on a Windows or Mac computer using NVDA, JAWS, or
              VoiceOver, as appropriate. Proprietary software is used for all
              automated testing. <br />
              We will monitor the website regularly to maintain our ongoing
              commitment to delivering a site that is accessible to the largest
              possible audience. If you have any concerns about the usability of
              our site or find a feature that is inaccessible, please contact us
              using the information below. We will make every reasonable effort
              to accommodate requests.
            </Trans>
          </p>

          <h3>
            <Trans>Connect with us</Trans>
          </h3>
          <p>
            <Trans>
              If you enjoyed using the a11ySolutions website, or if you had
              issues with any part of it, please get in touch. We'd love to hear
              from you in any of the following ways:
            </Trans>
          </p>
          <ul>
            <li>
              <Trans>Phone: +1 407-535-1932</Trans>
            </li>
            <li>
              <Trans>
                Email:
                <a href="mailto:a11ycontact@allysolutions.com">
                  a11ycontact@allysolutions.com
                </a>
              </Trans>
            </li>
          </ul>
          <p>
            <Trans>
              This document was last updated by a11ySolutions on January, 2025.
            </Trans>
          </p>
        </div>
        <div style={{ position: "relative", width: "100%", height: "auto" }}>
          <ImageDivider bottom={true} />
        </div>
      </div>
    </div>
  );
}

export default AccessibilityStatement;
