import React from "react";
import BicolorTitledText from "../BicolorTitledText/BicolorTitledText";
// import Button from '../Button/Button'
import "./OurProducts.css";
import { ImageDivider } from "../../UI/ImageDivider/ImageDivider";
import LinkButton from "../LinkButton/LinkButton";
import { useLingui } from "@lingui/react";
import { msg, Trans, t } from "@lingui/macro";

function OurProducts() {
  const { _ } = useLingui();
  return (
    <div id="our-products" className="our-products-page-container">
      <div className="home-services-bicolored-text">
        <BicolorTitledText
          className="our-products-biColor"
          color1="black"
          color2="#0D9E71"
          color1Text={t`Our`}
          color2Text={t`products`}
        ></BicolorTitledText>
      </div>

      <div className="our-products" role="list">
        <div className="our-products-content" role="listitem">
          <div className="our-products-item_photo">
            <img
              src="/alliBot.svg"
              className="img-alliBot"
              alt={_(
                msg`No more endless Googling or diving into forums for answers to your web accessibility questions. A11iBot provides immediate, expert guidance, effectively becoming your on-demand accessibility consultant.`
              )}
              style={{}}
            />
          </div>
          <div className="our-products-item_text">
            <h3>
              <Trans>
                A11iBot: Your AI-driven expert for instant and tailored
                accessibility solutions
              </Trans>
            </h3>
            <p>
              <Trans>
                No more endless Googling or diving into forums for answers to
                your web accessibility questions. A11iBot provides immediate,
                expert guidance, effectively becoming your on-demand
                accessibility consultant.
              </Trans>
            </p>
            <LinkButton
              href="/alliBot"
              isExternal={false}
              size="large"
              ariaLabel={_(
                msg`Learn More about A11iBot: Your AI-driven expert for instant and tailored accessibility solutions`
              )}
            >
              <Trans>Learn More</Trans>
            </LinkButton>
          </div>
        </div>

        <div className="our-products-content reverse" role="listitem">
          <div className="our-products-item_photo">
            <img
              src="/alliWidget.png"
              className=""
              alt={_(
                msg`Provide real-time accessibility adjustments. The A11iWidget ensures that all visitors, regardless of their abilities, can interact with your site effortlessly. By integrating our Accessibility Widget, you're not just adding a feature to your website; you're elevating the entire user experience.`
              )}
              style={{}}
            />
          </div>
          <div className="our-products-item_text">
            <h3>
              <Trans>A11iWidget: Real-Time Solutions, Zero Delays</Trans>
            </h3>
            <p>
              <Trans>
                Provide real-time accessibility adjustments. The A11iWidget
                ensures that all visitors, regardless of their abilities, can
                interact with your site effortlessly. By integrating our
                Accessibility Widget, you're not just adding a feature to your
                website; you're elevating the entire user experience.
              </Trans>
            </p>
            <LinkButton
              size="large"
              href="/alliWidget"
              isExternal={false}
              ariaLabel={_(
                msg`Learn More about A11iWidget: Real-Time Solutions, Zero Delays`
              )}
            >
              <Trans>Learn More</Trans>
            </LinkButton>
          </div>
        </div>
      </div>
      <ImageDivider bottom variantBottomBlack />
    </div>
  );
}

export default OurProducts;
