import React, { useEffect } from "react";
import BicolorTitledText from "../UI/BicolorTitledText/BicolorTitledText";
import CheckedItem from "../UI/CheckedItem/CheckedItem";
import FirmSamples from "../UI/FirmSamples/FirmSamples";
import VerticalCard from "../UI/VerticalCard/VerticalCard";

import { Helmet } from "react-helmet";
import { scrollToContactForm } from "../Utils/Utils";

import "./StandardRegulations.css";
import HomeIlustration from "../UI/HomeIlustration/HomeIlustration";
import LinkButton from "../UI/LinkButton/LinkButton";
import { Trans, t, msg } from "@lingui/macro";
import GetInTouchButton from "../UI/GetInTouchButton/GetInTouchButton";
import { useLingui } from "@lingui/react";

function StandardRegulations() {
    const { _, i18n } = useLingui();

    return (
        <div className="page-container">
            <Helmet>
                <title>{t`Standards | A11ysolutions`}</title>
            </Helmet>

            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    margin: "0 90px 60px 80px",
                }}
            >
                <div className="a11y-home-presentation-container">
                    <div className="presentation-sign home-presentation-sign-custom-text">
                        <h1 tabIndex="-1" style={{ zIndex: 2, width: "" }}>
                            <Trans>
                                <b>
                                    Web Accessibility <br />
                                    Standards
                                </b>
                            </Trans>
                        </h1>

                        <p>
                            <Trans>
                                We are committed to delivering <br />
                                your product with the best quality ever.
                            </Trans>
                        </p>

                        <LinkButton
                            id="presentation-button"
                            size={i18n.locale == "es" ? "extra" : "large"}
                            style={{ marginTop: "24px" }}
                            className=""
                            onClick={scrollToContactForm}
                        >
                            <Trans>Get a free consultation</Trans>
                        </LinkButton>
                    </div>
                    <div className="home-page-ilustration-container">
                        <HomeIlustration
                            firstPillText={t`Functionality`}
                            secondPillText={t`Accessibility`}
                            thirdPillText={t`Automation`}
                        />
                    </div>
                </div>
            </div>

            <div className="vertical-cards-container" id="compliance-section">
                <div className="v-card-subgroup">
                    <VerticalCard
                        title={_(msg`Americans with Disabilities Act (ADA)`)}
                        imgUrl={"/ada-logo.svg"}
                        btnText={_(msg`Find out more`)}
                        btnUrl={"https://www.ada.gov/"}
                        linkAriaLabel={_(
                            msg`Find out more about Americans with Disabilities Act (ADA)`
                        )}
                    >
                        <Trans>
                            The Americans with Disabilities Act of 1990 is a
                            civil rights law that prohibits discrimination based
                            on disability. It affords similar protections
                            against discrimination to Americans with
                            disabilities as the Civil Rights Act of 1964, which
                            made discrimination based on race, religion, sex,
                            national origin, and other characteristics illegal.
                        </Trans>
                    </VerticalCard>
                    <VerticalCard
                        title={_(
                            msg`Section 508 Amendment to the Rehabilitation Act`
                        )}
                        imgUrl={"/508-amendment.svg"}
                        btnText={_(msg`Find out more`)}
                        btnUrl={
                            "https://www.section508.gov/manage/laws-and-policies/"
                        }
                        linkAriaLabel={t`Find out more about Section 508 Amendment to the Rehabilitation Act`}
                    >
                        <Trans>
                            Section 508 requires federal agencies to make their
                            electronic and information technology accessible to
                            people with disabilities. Ensures equal access to
                            information and data for everyone, regardless of
                            disability. Compliance is mandatory for federal
                            agencies and influences accessibility practices in
                            the private sector and educational institutions.
                        </Trans>
                    </VerticalCard>
                    {/* </div> */}
                    {/* <div className="v-card-subgroup"> */}
                    <VerticalCard
                        title={_(
                            msg`Web Content Accessibility Guidelines (WCAG)`
                        )}
                        imgUrl={"/wcag-logo.svg"}
                        btnText={_(msg`Find out more`)}
                        btnUrl={"https://www.w3.org/TR/WCAG22/"}
                        linkAriaLabel={_(
                            msg`Find out more about Web Content Accessibility Guidelines (WCAG)`
                        )}
                    >
                        <Trans>
                            WCAG defines how to make Web content more accessible
                            to people with disabilities. Accessibility involves
                            a wide range of disabilities, including visual,
                            auditory, physical, speech, cognitive, language,
                            learning, and neurological disabilities. These
                            guidelines also make content more usable by older
                            individuals and improve usability for users in
                            general.
                        </Trans>
                    </VerticalCard>
                    <VerticalCard
                        title={_(msg`European Standard EN 301 549`)}
                        imgUrl={"/european-standard.svg"}
                        btnText={_(msg`Find out more`)}
                        btnUrl={`https://digital-strategy.ec.europa.eu/en/policies/latest-changes-accessibility-standard`}
                        linkAriaLabel={_(
                            msg`Find out more about European Standard EN 301 549`
                        )}
                    >
                        <Trans>
                            The European Standard EN 301 549 ensures digital
                            accessibility for all. Developed by ETSI, it
                            outlines ICT accessibility requirements for
                            websites, apps, and hardware. By adhering to EN 301
                            549, organizations can create digital offerings that
                            are usable for everyone, thereby empowering all
                            users to participate online.
                        </Trans>
                    </VerticalCard>
                </div>
            </div>

            <section className="shadowed-section shadowed-regulations">
                <BicolorTitledText
                    color1="black"
                    color2="#0D9E71"
                    color1Text={t`Web Accessibility`}
                    color2Text={t`Lawsuits Samples`}
                    id="tablist-1"
                />
                <FirmSamples />
            </section>

            <section className="info-with-image regulation-section first-info">
                <div className="checked-info">
                    <BicolorTitledText
                        color1="#0D9E71"
                        color2="black"
                        color1Text={t`Tips for being`}
                        color2Text={t`accessibility compliant`}
                        className="checked-info-header"
                    >
                        <span>
                            <Trans>
                                Whether your organization is already involved in
                                litigation or is proactive with accessibility,
                                the best course of action is to make your
                                website or app conformant with WCAG 2.0/2.1
                                Level AA.
                                <br />
                                <br />
                            </Trans>
                        </span>
                        <h3 className="not-bold-h3">
                            <Trans>
                                AS A START, WE RECOMMEND THE FOLLOWING TIPS:
                            </Trans>
                        </h3>
                    </BicolorTitledText>

                    <div role="list">
                        <CheckedItem>
                            <Trans>
                                <b>Fix the technical basics.</b> The most common
                                ADA lawsuit complaints about websites are
                                missing alt text, missing labels, empty links,
                                redundant links, and missing page titles. For
                                apps, they include incompatibility with screen
                                reader technology, missing alt text, and missing
                                navigation links.
                            </Trans>
                        </CheckedItem>
                        <CheckedItem>
                            <Trans>
                                <b>
                                    Include people with disabilities in your
                                    user testing.
                                </b>{" "}
                                Though automated scans are a helpful first step
                                in identifying problems, they cannot always
                                emulate the nuances of human interactions with
                                digital interfaces. User testing that includes
                                people with disabilities gives you a more
                                holistic picture of your website’s or app’s user
                                experience.
                            </Trans>
                        </CheckedItem>
                        <CheckedItem>
                            <Trans>
                                <b>Commit to long-term maintenance.</b> No
                                software can instantly identify, let alone fix,
                                all of your digital accessibility problems once
                                and for all. The digital landscape is
                                ever-evolving, as are human users. True
                                accessibility requires a long-term,
                                comprehensive digital accessibility testing,
                                remediation, and maintenance program.
                            </Trans>
                        </CheckedItem>
                    </div>

                    <GetInTouchButton />
                </div>

                <img
                    src="/encircled-bg-large.png"
                    alt=""
                    className="regulations-desktop-img"
                />
            </section>

            <section className="shadowed-section regulations">
                <BicolorTitledText
                    color1="black"
                    color2="#0D9E71"
                    color1Text={t`What to do next?`}
                    color2Text=""
                >
                    <Trans>
                        We can help you meet the WCAG standards. Connect with us
                        today to learn more about our comprehensive approach to
                        digital accessibility, including our automated and
                        manual auditing capabilities and extensive range of
                        managed services.
                    </Trans>
                </BicolorTitledText>

                <GetInTouchButton />
            </section>
        </div>
    );
}

export default StandardRegulations;
