import React from "react";
import "./ServiceCard.css";
// import Button from '../Button/Button'
import LinkButton from "../LinkButton/LinkButton";
import { Trans } from "@lingui/macro";

function ServiceCard({
  children,
  header,
  imgSrc,
  imgColor = "white",
  style,
  href,
  imgStyle,
  imgClass,
  ariaLabel,
  buttonDisabled = false,
}) {
  return (
    <div
      className="service-card-container"
      style={{ ...style }}
      role={"listitem"}
    >
      <div
        className="service-card"
        style={{ backgroundColor: imgColor, ...imgStyle }}
      >
        <img alt="" src={imgSrc} className={imgClass} />
      </div>
      <div className="service-card-content">
        <h3>{header}</h3>
        <div className="service-card-text">{children}</div>
        <div style={{ marginTop: "27px" }}>
          <LinkButton
            variant="negative"
            href={href}
            isExternal={false}
            ariaLabel={ariaLabel}
            disabled={buttonDisabled}
            className={buttonDisabled && "a11y-link-disabled"}
          >
            {buttonDisabled ? (
              <Trans>Coming soon</Trans>
            ) : (
              <Trans>Learn More</Trans>
            )}
          </LinkButton>
        </div>
      </div>
    </div>
  );
}

export default ServiceCard;
