import "../../Home/Home.css";
import { scrollToContactForm } from "../../Utils/Utils";
import BicolorTitledText from "../BicolorTitledText/BicolorTitledText";
import ToolList from "../ToolList/ToolList";

import "./Toolset.css";
import LinkButton from "../LinkButton/LinkButton";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";

function Toolset() {
    const { i18n } = useLingui();
    return (
        <div className="toolset-container">
            <BicolorTitledText
                color1="black"
                color2="#0D9E71"
                color1Text={t`Our`}
                color2Text={t`toolset`}
            >
                <p>
                    <Trans>
                        With the tools below and beyond we ensure your product
                        is ready to go out to the market.
                    </Trans>
                </p>

                <LinkButton
                    size={i18n.locale == "es" ? "extra" : "large"}
                    className="toolset-link"
                    onClick={scrollToContactForm}
                >
                    <Trans>Get a free consultation</Trans>
                </LinkButton>
            </BicolorTitledText>
            {/* <div className="toolset-mobile-circle"></div> */}
            <div className="toolset-tablet-circle"></div>
            <div className="toolset-desktop-1-circle"></div>
            <div className="toolset-desktop-2-circle"></div>

            <ToolList></ToolList>
        </div>
    );
}

export default Toolset;
