import React from "react";
import { Helmet } from "react-helmet";
import HomeIlustration from "../UI/HomeIlustration/HomeIlustration";
import Presentation from "../UI/Presentation/Presentation";
import BicolorTitledText from "../UI/BicolorTitledText/BicolorTitledText";
import CustomCard from "../UI/CustomCard/CustomCard";
import TryItNow from "../UI/TryItNow/TryItNow";
import "./AlliBot.css";
import { ImageDivider } from "../UI/ImageDivider/ImageDivider";
import ListPricing from "../UI/ListPricing/ListPricing";
import ReviewCard from "../UI/ReviewCard/ReviewCard";
import { pricingPlanPackages, openInNewTab } from "../Utils/Utils";
import LinkButton from "../UI/LinkButton/LinkButton";
import { t, Trans, msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";

function AlliBot() {
  const { _ } = useLingui();
  const pricingPackagesBot = pricingPlanPackages().packages.filter(
    (p) => p.name !== "A11iWidget Esential"
  );
  const reviewA11iBot = [
    {
      name: t`Alberto Roman, CEO/Founder at Front10 and Woxo`,
      comment: t`A11iBot's AI sophistication has been a game-changer for web accessibility, offering real-time coding solutions that streamline our process, saving us hours of work.`,
      image: "/alberto.jpg",
    },
    {
      name: t`Yainery Bolanos, CEO/Co-Founder at Adaved`,
      comment: t`We just started using A11iBot and it's already helping us a lot. It's like having a specialist with us all the time, making our work faster and better.`,
      image: "/yainerys.jpg",
    },
    {
      name: t`Dariel Vila, CEO/Co-Founder at Front10 and Woxo`,
      comment: t`A11iBot has significantly impacted our work. Its expert consultations on WAI-ARIA have remarkably enhanced our efficiency, making it an essential asset for our team.`,
      image: "/dariel.jpg",
    },
    {
      name: t`Luis Terrero, CEO/Founder at PixelPerfecto`,
      comment: t`We recently started using A11iBot and we're very happy. Even in its MVP stage, the chatbot is like an expert on web accessibility. It gives quick and useful advice.`,
      image: "/luis_alberto.jpg",
    },
  ];
  return (
    <div id="alliBot" className="alliBot-page-container">
      <Helmet>
        <title>
          {_(msg`A11iBot - Empowering Inclusive Experiences | A11ysolutions`)}
        </title>
      </Helmet>

      {/* =============== alliBot-presentation-container =============== */}
      <div className="alliBot-presentation-container">
        <div className="page-top">
          <Presentation
            title={t`Need Quick Solutions for Web Accessibility?`}
            texts={[
              t`Unlock Instant Answers with A11iBot, Your AI-Powered Web Accessibility Expert.`,
            ]}
            href="https://allibot.a11ysolutions.com/"
            buttonText={t`Try It Free`}
            isLink
            className="alliBot-presentation-sign"
          />
        </div>
        <div className="privacy-policy-ilustration-container">
          <HomeIlustration src="/alliBot.svg" />
        </div>
      </div>

      {/* =============== alliBot-content ===============  */}
      <div className="alliBot-content">
        {/* =============== alliBot-accessibility ===============  */}
        <div className="alliBot-accessibility">
          <div className="home-services-bicolored-text">
            <BicolorTitledText
              color2="#fff"
              color2Text={t`Struggling with Web Accessibility?`}
              className="home-our-services"
            ></BicolorTitledText>
          </div>
          <div className="alliBot-accessibility-content">
            <CustomCard className="accessibility-item">
              <h3>
                <Trans>Ever Felt Lost in Endless Documentation?</Trans>
              </h3>
              <p>
                <Trans>
                  You're navigating a labyrinth of complex guidelines,
                  conflicting tutorials, and outdated articles. Each click feels
                  like another wrong turn, trapping you deeper in a maze with no
                  escape.
                </Trans>
              </p>
            </CustomCard>
            <CustomCard className="accessibility-item">
              <h3>
                <Trans>Anxious About Compliance Risks?</Trans>
              </h3>
              <p>
                <Trans>
                  The looming threat of legal actions and penalties hangs over
                  you like a dark cloud. Each project feels like a gamble, a
                  tightrope walk over a chasm of financial and reputational
                  ruin.
                </Trans>
              </p>
            </CustomCard>
            <CustomCard className="accessibility-item">
              <h3>
                <Trans>Struggling to Keep Up with Constant Updates?</Trans>
              </h3>
              <p>
                <Trans>
                  Just when you've finally caught up, the goalposts move. New
                  updates roll out, and you're back at square one, scrambling to
                  relearn what you thought you already knew.
                </Trans>
              </p>
            </CustomCard>
            <CustomCard className="accessibility-item">
              <h3>
                <Trans>Tired of Expensive Consultants?</Trans>
              </h3>
              <p>
                <Trans>
                  Your budget is bleeding, drained by hefty fees that promise
                  expertise but often underdeliver. It's like being stuck in a
                  financial sinkhole that keeps pulling you deeper into debt.
                </Trans>
              </p>
            </CustomCard>
          </div>

          <LinkButton
            href="https://allibot.a11ysolutions.com/"
            variant="negative"
            size="large"
          >
            <Trans>Try It Free</Trans>
          </LinkButton>

          <ImageDivider top bottom variantTopGreen />
        </div>

        {/* =============== alliBot-features ===============  */}
        <div className="alliBot-features">
          <div className="home-services-bicolored-text">
            <BicolorTitledText
              color1="#0D9E71"
              color1Text={t`Introducing A11iBot: The easiest way to solve your Web Accessibility Needs`}
              className="home-our-services"
            ></BicolorTitledText>
          </div>

          <div className="alliBot-features-content">
            <CustomCard
              imgSrc="/undraw_accessible_efforlessly.svg"
              imgDiv="/back-mac.png"
              classImgDivBrowser="imgDiv-mac"
              className="custom-sized-image"
            >
              <h3>
                <Trans>Cut Through the Documentation Jungle</Trans>
              </h3>
              <p>
                <Trans>
                  Lost in a maze of accessibility guidelines? Whether you're a
                  developer working to meet Web Content Accessibility Guidelines
                  (WCAG) or a tester looking to identify accessibility flaws,
                  A11iBot has you covered. Get immediate, accurate answers and
                  make your web content more accessible efforlessly.
                </Trans>
              </p>
            </CustomCard>
            <CustomCard
              imgSrc="/undraw_eliminate_compliance_nightmares.svg"
              imgDiv="/back-browser.png"
              classImgDivBrowser="imgDiv-browser"
              className="shadow-none custom-sized-image image-increase-height"
            >
              <h3>
                <Trans>Eliminate Compliance Nightmares</Trans>
              </h3>
              <p>
                <Trans>
                  Legal worries? Set them aside with A11iBot. Specializing in
                  the latest WCAG guidelines, A11iBot assists you in achieving
                  compliance with laws like ADA, Section 508, and EAA, among
                  others. A11iBot provides code snippets that are compliant with
                  the latest standards, all backed by 5 years of
                  enterprise-level expertise.
                </Trans>
              </p>
            </CustomCard>
            <CustomCard
              imgSrc="/undraw_stay_ahead.svg"
              imgDiv="/back-mac.png"
              classImgDivBrowser="imgDiv-mac"
              className="shadow-none custom-sized-image"
            >
              <h3>
                <Trans>Stay Ahead of the Ever-Changing Game</Trans>
              </h3>
              <p>
                <Trans>
                  Adapt instantly to new Standard/Laws updates with code
                  solutions that are always up-to-date. A11iBot keeps you ahead
                  of the curve, so you never have to play catch-up.
                </Trans>
              </p>
            </CustomCard>
            <CustomCard
              imgSrc="/undraw_break_free_consultant.svg"
              imgDiv="/back-browser.png"
              classImgDivBrowser="imgDiv-browser"
              className="custom-sized-image image-increase-height"
            >
              <h3>
                <Trans>Break Free from the Costly Consultant Trap</Trans>
              </h3>
              <p>
                <Trans>
                  Forget about those expensive consultants. A11iBot offers you
                  guidance and code solutions you need at a fraction of the
                  cost, directly in your chat window.
                </Trans>
              </p>
            </CustomCard>
          </div>

          <LinkButton href="https://allibot.a11ysolutions.com/" size="large">
            <Trans>Try It Free</Trans>
          </LinkButton>
        </div>

        {/* =============== alliBot-why-choose ===============  */}
        <div className="alliBot-why-choose">
          <div className="home-services-bicolored-text">
            <BicolorTitledText
              color2="#fff"
              color2Text={t`Why Choose A11iBot? Unbeatable Features for Unparalleled Ease`}
              className="home-our-services"
            ></BicolorTitledText>
          </div>
          <div className="alliBot-why-choose-content">
            <CustomCard className="why-choose-item custom-why-choose-card">
              <h3>
                <Trans>AI-Powered Precision</Trans>
              </h3>
              <p>
                <Trans>
                  Leverage the power of AI for pinpoint accuracy. A11iBot
                  understands your queries and provides solutions that hit the
                  mark, every time.
                </Trans>
              </p>
            </CustomCard>
            <CustomCard className="why-choose-item custom-why-choose-card">
              <h3>
                <Trans>Immediate Code Solutions</Trans>
              </h3>
              <p>
                <Trans>
                  Why wait? A11iBot delivers real-time code snippets right in
                  the chat. Just copy, paste, and watch your accessibility
                  issues disappear.
                </Trans>
              </p>
            </CustomCard>
            <CustomCard className="why-choose-item custom-why-choose-card">
              <h3>
                <Trans>Up-to-Date Expertise</Trans>
              </h3>
              <p>
                <Trans>
                  Never worry about outdated information. A11iBot is
                  continuously updated to reflect the latest web accessibility
                  standards and practices.
                </Trans>
              </p>
            </CustomCard>
            <CustomCard className="why-choose-item custom-why-choose-card">
              <h3>
                <Trans>Enterprise-Level Reliability</Trans>
              </h3>
              <p>
                <Trans>
                  Rely on the same expertise trusted by major industries, from
                  airlines to banks. A11iBot brings you 5 years of proven
                  experience in a user-friendly chat interface.
                </Trans>
              </p>
            </CustomCard>
            <CustomCard className="why-choose-item custom-why-choose-card">
              <h3>
                <Trans>Affordable Expertise</Trans>
              </h3>
              <p>
                <Trans>
                  Get top-notch accessibility advice without the top-tier price
                  tag. A11iBot provides expert solutions at a fraction of the
                  cost of traditional consulting.
                </Trans>
              </p>
            </CustomCard>
            <CustomCard className="why-choose-item custom-why-choose-card">
              <h3>
                <Trans>24/7 Accessibility</Trans>
              </h3>
              <p>
                <Trans>
                  Questions don't keep office hours, and neither does A11iBot.
                  Get the answers you need, any time of the day or night.
                </Trans>
              </p>
            </CustomCard>
          </div>

          <ImageDivider top />
        </div>

        {/* =============== Alliwidget Pricing ===============  */}
        <div id="a11ybot-pricing" className="alliWidget-pricing">
          <div className="pricing-plan-heading">
            <h2 className="pricing-title">{pricingPlanPackages().title}</h2>
          </div>
          <ListPricing
            pricingPlan={pricingPackagesBot}
            deletePlan="A11iWidget Esential"
          />
          <ImageDivider top variantTopBlack />
        </div>

        {/* =============== tryItNow ===============  */}
        <TryItNow
          handleNavigate={() =>
            openInNewTab("https://allibot.a11ysolutions.com/")
          }
          text={t`Give it a try for free`}
          buttonText={t`Try It Now`}
        >
          <ImageDivider top bottom />
        </TryItNow>

        {/* =============== alliBot-review ===============  */}
        <div className="alliBot-review">
          <div className="alliBot-review-header">
            <div className="home-services-bicolored-text">
              <BicolorTitledText
                color1="black"
                color2="#0D9E71"
                color2Text={t`Discover our customers' opinions`}
                className="home-our-services"
              ></BicolorTitledText>
              <p className="alliWidget-review-subtitle">
                <Trans>Listen to the Voices that Count!</Trans>
              </p>
            </div>
          </div>
          <div className="alliWidget-review-container">
            <div className="alliWidget-review-content">
              {reviewA11iBot.map((rev, index) => (
                <ReviewCard key={index} review={rev} />
              ))}
            </div>
          </div>
          <ImageDivider bottom variantBottomBlack />
        </div>
      </div>
    </div>
  );
}

export default AlliBot;
