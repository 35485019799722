import React from "react";
import AchievementCard from "../UI/AchievementCard/AchievementCard";
import BicolorTitledText from "../UI/BicolorTitledText/BicolorTitledText";
import Presentation from "../UI/Presentation/Presentation";
import Illustration from "../UI/Illustration/Illustration";
import Toolset from "../UI/Toolset/Toolset";
import CheckedItem from "../UI/CheckedItem/CheckedItem";

import { Helmet } from "react-helmet";
import { MdBugReport } from "react-icons/md";
import { IoExtensionPuzzle, IoPersonCircle } from "react-icons/io5";
import { AiOutlineSync } from "react-icons/ai";
import { GoGear } from "react-icons/go";
import { FaClipboardList, FaSearchPlus } from "react-icons/fa";
import { BsShieldFillCheck } from "react-icons/bs";

import "./Functional.css";
import { Trans, msg, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import GetInTouchButton from "../UI/GetInTouchButton/GetInTouchButton";

const BUG_ICON = <MdBugReport size="28" role="img" aria-hidden="true" />;
const PUZZLE_ICON = (
    <IoExtensionPuzzle size="25" role="img" aria-hidden="true" />
);
const PERSON_ICON = <IoPersonCircle size="25" role="img" aria-hidden="true" />;
const SYNC_ICON = <AiOutlineSync size="28" role="img" aria-hidden="true" />;
const GEAR_ICON = <GoGear size="28" role="img" aria-hidden="true" />;
const CLIPBOARD_ICON = (
    <FaClipboardList size="28" role="img" aria-hidden="true" />
);
const SEARCH_ICON = <FaSearchPlus size="25" role="img" aria-hidden="true" />;
const SHIELD_ICON = (
    <BsShieldFillCheck size="25" role="img" aria-hidden="true" />
);

function Functional() {
    const { _ } = useLingui();

    return (
        <div id="functional-main" className="page-container">
            <Helmet>
                <title>
                    {_(msg`Functional testing services | A11ysolutions`)}
                </title>
            </Helmet>

            <div className="page-top">
                <Presentation
                    title={t`Scalable Functional Testing Services`}
                    texts={[
                        t`Discover how you can instantly and`,
                        t`effortlessly find the bugs impacting`,
                        t`your users enabling you to release`,
                        t`high-quality at speed.`,
                    ]}
                    isLink
                    buttonText={t`Get a free consultation`}
                />
            </div>

            <Illustration
                pillText={t`Functionality`}
                imgSrc={
                    window.innerWidth >= 768
                        ? "/desktop@2x.png"
                        : "/desktop.png"
                }
                containerStyle={{ position: "relative", marginTop: "60px" }}
                pillPositionClass="functional-pill-position"
            />

            <section className="functional-info-with-image first-info">
                <div className="functional-checked-info">
                    <BicolorTitledText
                        color1="#0D9E71"
                        color2="black"
                        color1Text={t`Our expert advice can help you`}
                        color2Text={t`solve your QA bottlenecks`}
                        className="functional-checked-info-header"
                    >
                        <p>
                            <Trans>
                                Without consistently delivering a quality
                                experience, brands and enterprises can’t fully
                                guarantee customer satisfaction.
                                <br></br>
                                <br></br>
                                We help development teams deliver exceptional
                                products at speed with the best customer
                                experience. Our functional testing process is
                                built around your needs, and we integrate with
                                the tools you already use.
                            </Trans>
                        </p>
                    </BicolorTitledText>

                    <GetInTouchButton style={{ marginLeft: "20px" }} />
                </div>

                <div className="functional-intermediate-image">
                    <div className="functional-first-encircled-img">
                        <img
                            src="/desktop-two-monitors.png"
                            alt=""
                            className="functional-first-image"
                        />
                    </div>
                    <div className="functional-first-blurred-circle"></div>
                    <div className="functional-fourth-blurred-circle"></div>
                </div>
            </section>

            <section className="functional-info-with-image second-info">
                <div className="functional-checked-info">
                    <BicolorTitledText
                        color1="#0D9E71"
                        color2="black"
                        color1Text={t`What can we help`}
                        color2Text={t`you achieve today?`}
                        className="functional-checked-info-header"
                    >
                        <div className="functional-explanation-header">
                            <h3>
                                <Trans>
                                    ELEVATE YOUR DIGITAL EXPERIENCE WITH
                                    UNBEATABLE QUALITY AND COVERAGE
                                </Trans>
                            </h3>
                        </div>
                    </BicolorTitledText>

                    <div className="bicolor-titled-container" role="list">
                        <CheckedItem>
                            <Trans>
                                Scale your device coverage testing using
                                real-world different devices, screen
                                resolutions, browsers, and platforms, with no
                                emulators.
                            </Trans>
                        </CheckedItem>
                        <CheckedItem>
                            <Trans>
                                Every defect is manually reproduced, verified,
                                and categorized by severity, to allow rapid
                                fixes.
                            </Trans>
                        </CheckedItem>
                        <CheckedItem>
                            <Trans>
                                Bring your internal teams together and improve
                                your working practices across the entire SDLC.
                            </Trans>
                        </CheckedItem>
                        <CheckedItem>
                            <Trans>
                                Detailed issue reports integrate seamlessly into
                                your own platform(s).
                            </Trans>
                        </CheckedItem>
                    </div>

                    <GetInTouchButton style={{ marginLeft: "20px" }} />
                </div>

                <div className="functional-intermediate-image">
                    <img
                        src="/coding-man.png"
                        alt=""
                        className="functional-second-image"
                    />
                    <div className="functional-second-image-container">
                        <img
                            src="/computer-plant.png"
                            alt=""
                            className="functional-third-image"
                        />
                        <img
                            src="/hand-bar.png"
                            alt=""
                            className="functional-fourth-image"
                        />
                    </div>
                    <div className="functional-second-circle-container">
                        <div className="functional-second-circle"></div>
                        <div className="functional-second-blurred-circle"></div>
                    </div>
                </div>
            </section>

            <section className="functional-info-with-image second-info">
                <div className="functional-circles-container">
                    <div className="functional-right-circle"></div>
                    <div className="functional-left-circle"></div>
                </div>

                <div className="functional-checked-info">
                    <BicolorTitledText
                        color1="#0D9E71"
                        color2="black"
                        color1Text=""
                        color2Text=""
                    >
                        <div className="functional-second-explanation-header">
                            <h3>
                                <Trans>UNLIMITED SCALE, AT SPEED</Trans>
                            </h3>
                        </div>
                    </BicolorTitledText>

                    <div
                        className="functional-qualities-top-section"
                        role={"list"}
                    >
                        <div className="card-subgroup">
                            <AchievementCard imgSrc="folder-data.svg">
                                <p>
                                    <Trans>
                                        <span style={{ fontWeight: "bold" }}>
                                            Fully verified and reviewed results
                                        </span>{" "}
                                        within hours, with no extra triage
                                        required.
                                    </Trans>
                                </p>
                            </AchievementCard>

                            <AchievementCard imgSrc="data-user.svg">
                                <p>
                                    <Trans>
                                        <span style={{ fontWeight: "bold" }}>
                                            Shift your in-house testing workload
                                            to our 24/7/365
                                        </span>{" "}
                                        on-demand crowd to help you scale and
                                        execute tests in <br></br> real-time.
                                    </Trans>
                                </p>
                            </AchievementCard>
                        </div>

                        <div className="card-subgroup">
                            <AchievementCard imgSrc="searching.svg">
                                <p>
                                    <Trans>
                                        {" "}
                                        <span style={{ fontWeight: "bold" }}>
                                            Test overnight, weekends included,
                                        </span>{" "}
                                        thanks to our vetted global community of
                                        professional testers.
                                    </Trans>
                                </p>
                            </AchievementCard>

                            <AchievementCard imgSrc="verification.svg">
                                <p>
                                    <Trans>
                                        <span style={{ fontWeight: "bold" }}>
                                            Remove any potential QA bottlenecks
                                        </span>{" "}
                                        by speeding up your testing workflow.
                                    </Trans>
                                </p>
                            </AchievementCard>
                        </div>
                    </div>
                </div>
            </section>

            <section className="functional-info-with-image second-info">
                <div className="functional-checked-info">
                    <BicolorTitledText
                        color1="#0D9E71"
                        color2="black"
                        color1Text=""
                        color2Text=""
                    >
                        <div className="functional-explanation-header">
                            <h3>
                                <Trans>
                                    DELIVER GLOBAL RELEASES THAT DELIGHT USERS
                                </Trans>
                            </h3>
                        </div>
                    </BicolorTitledText>

                    <div className="bicolor-titled-container" role="list">
                        <CheckedItem>
                            <Trans>
                                Improve customer satisfaction earning the trust
                                and satisfaction of international users by
                                ensuring bugs don’t affect their interactions
                                with your brand and products.
                            </Trans>
                        </CheckedItem>
                        <CheckedItem>
                            <Trans>
                                Understand bug impact on local users ensuring
                                end-users feel as though the products you
                                deliver were built for them.
                            </Trans>
                        </CheckedItem>
                        <CheckedItem>
                            <Trans>
                                Test with real users on real devices for real
                                results ensuring a consistent user experience
                                for all end-users giving you a competitive edge
                                in the oversaturated app market.
                            </Trans>
                        </CheckedItem>
                    </div>

                    <GetInTouchButton style={{ marginLeft: "20px" }} />
                </div>
                <div className="functional-intermediate-image">
                    <div className="functional-first-encircled-img">
                        <img
                            src="/man-typing.png"
                            alt=""
                            className="functional-first-image"
                        />
                    </div>
                    <div className="functional-fifth-blurred-circle"></div>
                </div>
            </section>

            <section className="functional-info-with-image shadowed-section">
                <div className="functional-checked-info">
                    <BicolorTitledText
                        color1="#0D9E71"
                        color2="black"
                        color1Text={t`Functional Testing Services`}
                        color2Text={t`We Offer`}
                    ></BicolorTitledText>

                    <div className="checked-items" role="list">
                        <div>
                            <CheckedItem icon={BUG_ICON}>
                                <Trans>Smoke testing</Trans>
                            </CheckedItem>
                            <CheckedItem icon={PUZZLE_ICON}>
                                <Trans>Module / Component testing</Trans>
                            </CheckedItem>
                            <CheckedItem icon={SYNC_ICON}>
                                <Trans>Regression testing</Trans>
                            </CheckedItem>
                            <CheckedItem icon={GEAR_ICON}>
                                <Trans>Integration testing</Trans>
                            </CheckedItem>
                        </div>
                        <div>
                            <CheckedItem icon={CLIPBOARD_ICON}>
                                <Trans>Cross- platform testing</Trans>
                            </CheckedItem>
                            <CheckedItem icon={SHIELD_ICON}>
                                <Trans>Sanity testing</Trans>
                            </CheckedItem>
                            <CheckedItem icon={PERSON_ICON}>
                                <Trans>UAT testing</Trans>
                            </CheckedItem>
                            <CheckedItem icon={SEARCH_ICON}>
                                <Trans>Explorary </Trans>
                            </CheckedItem>
                        </div>
                    </div>
                </div>

                <div className="last-image">
                    <div className="functional-third-circle-container">
                        <div className="functional-third-circle"></div>
                        <div className="functional-third-blurred-circle"></div>
                        <div className="functional-fourth-circle"></div>
                    </div>
                    <img
                        src="/man-typing-and-diagram.png"
                        alt=""
                        className="functional-fith-image"
                    />
                </div>
            </section>

            <Toolset />
        </div>
    );
}

export default Functional;
