import React from "react";
import "./FooterLinks.css";
import { Trans } from "@lingui/macro";

function FooterLinks() {
  return (
    <div style={{ margin: "1em 0" }}>
      <nav className="footer-nav" aria-label="Footer links">
        <ul className="footer-links">
          {/* <li style={{}}>
                        <a
                            href="/standards"
                            id="skip-footer"
                            className="footer-link"
                        >
                            <Trans>Web Accessibility Standards</Trans>
                        </a>
                    </li> */}
          <li>
            <a href="/#our-services" className="footer-link">
              <Trans>Services</Trans>
            </a>
          </li>
          <li>
            <a href="/privacy-policy" className="footer-link">
              <Trans>Privacy Policy</Trans>
            </a>
          </li>
          <li>
            <a href="/a11y-statement" className="footer-link">
              <Trans>Accessibility Statement</Trans>
            </a>
          </li>
          <li>
            <a href="/sitemap" className="footer-link">
              <Trans>Sitemap</Trans>{" "}
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}
export default FooterLinks;
